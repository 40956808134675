import React, { useEffect } from "react";
import { Container } from "@mui/material";
import playbutton from "../image/icons/innerlobby/game entry pop up/1.png";
import { Block } from "@mui/icons-material";
import close from "../image/icons/innerlobby/game entry pop up/2.png";
// import { handleButtonClick } from "../component/Home";
import bg from "../image/icons/innerlobby/game entry pop up/4.png";
import online from "../image/icons/innerlobby/game entry pop up/6.png";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchData,
  getcarromControllerPrice
} from "../RaectRedux/ActionApiOfData";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
// import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Gamelobby({ onClose, buttonTpe }) {
  // console.log(buttonTpe, "buttonTpe");
  const naviagte = useNavigate();
  // const [loading, setLoading] = useState(false);
  const date = "";
  const dispatch = useDispatch();
  const data4 = useSelector((state) => state.add.api4Data);
  const data = useSelector((state) => state.add.api1Data);
  const jwtyoken = Cookies.get("token");
  const bycrypt = jwtDecode(jwtyoken);
  // const { username, _id: userid } = bycrypt;
  const username = "shubham";
  const userid = bycrypt._id;
  const status = 1;
  let walletAmount = data.length > 0 ? parseFloat(data[0].wallet) || 0 : 0;
  // console.log(bycrypt, "lhdakhkahkhk", data4);
  useEffect(() => {
    dispatch(fetchData(userid));
    // dispatch(getcarromControllerPrice());
  }, [userid, dispatch]);
  const handlePlayButtonClick = async (gameHistoryData) => {
    const gamepriceamount = gameHistoryData.gamePrice;
    if (gameHistoryData) {
      if (walletAmount === 0 || walletAmount <= gamepriceamount) {
        toast.error("Wallet does not have sufficient amount", {
          position: toast.POSITION.TOP_CENTER
        });
        return;
      }
    }
    let wallet = walletAmount - gamepriceamount;
    // const wallet = 30;
    try {
      const gameHistoryResponse = await axios.post(
        "https://ruby-important-panther.cyclic.app/Gamehistory",
        {
          userid,
          wallet,
          gameHistoryData
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      if (gameHistoryResponse.status === 200) {
        if (buttonTpe === "patti") {
          window.location = "https://teenpattimax.netlify.app/";
        } else if (buttonTpe === "Roulette") {
          window.location = "https://roulletemax.netlify.app/";
        } else {
          // Log the value of buttonTpe when the alert is triggered
          // console.log("buttonTpe not recognized:", buttonTpe);

          alert("This game does not exist");
        }
        // If the request was successful (status code 200)
        // console.log(
        //   gameHistoryResponse.data.data.gameHistory[0].gameName,
        //   "ata.gameHistory"
        // );
        // naviagte("/");
        dispatch(fetchData(userid));
      } else if (gameHistoryResponse.status === 404) {
        // If the user was not found (status code 404)
        console.log("User not found with the given userid");
      } else {
        // Handle other status codes if needed
        console.log("An error occurred:", gameHistoryResponse.data.error);
        //   }
      }
    } catch (error) {
      //   // Handle network or other errors
      console.error("Anhaha error occurred:", error);
    }
  };

  return (
    <Container
      style={{
        backgroundColor: "#4c1e14",
        width: "100%",
        height: "96%",
        paddingLeft: "0px",
        paddingRight: "0px",
        overflow: "auto",
        paddingTop: "0px",
        textAlign: "center"
      }}
    >
      <div
        style={{
          textAlign: "-moz-right",
          height: "50px",
          position: "sticky",
          top: "0",
          backgroundColor: "#4c1e14"
        }}
      >
        <div width={"120px"} style={{ position: "relative" }}>
          <img
            src={online}
            width={"100px"}
            style={{ display: "block" }}
            alt=""
          />
          <div style={{ position: "absolute", top: "2px", right: "2px" }}>
            <img
              src={close}
              onClick={onClose}
              style={{ width: "30px", display: Block }}
              alt=""
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          color: "yellow",
          scrollbar: {
            width: 0,
            background: "transparent"
          },
          backgroundImage: `url(${bg})`,
          backgroundSize: "100% 180px",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed"
        }}
      >
        <table style={{ scrollbar: { width: 0, background: "transparent" } }}>
          <thead
            style={{
              position: "sticky",
              top: "52px",
              backgroundColor: "#622a2a"
            }}
          >
            <tr>
              <th style={{ paddingRight: "10px" }}>Game Name</th>
              <th style={{ paddingRight: "10px" }}>Game Price</th>
              <th style={{ paddingRight: "10px" }}>Player</th>
              <th style={{ paddingRight: "10px" }}>Winer Price</th>
            </tr>
          </thead>
          <tbody>
            {data4.map((game, index) => (
              <tr key={index}>
                <td style={{ paddingRight: "10px" }}>{game.gameName}</td>
                <td style={{ paddingRight: "10px" }}>{game.gamePrice}</td>
                <td style={{ paddingRight: "10px" }}>{game.player}</td>
                <td style={{ paddingRight: "10px" }}>{game.winerPrice}</td>
                <td
                  onClick={() =>
                    handlePlayButtonClick({ ...game, username, status, date })
                  }
                >
                  <img src={playbutton} width={"100px"} alt="" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ToastContainer />
    </Container>
  );
}

export default Gamelobby;
