import { Container, Grid } from "@mui/material";
import React, { useState, useRef } from "react";
// import img from "../Images/logo3.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import loginIn from "../image/icons/graphics (1)/logn.png";
// import login from "../image/icons/RANK/RANK/13.webp";
// import login from "../image/icons/graphics (1)/logn.png";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
// import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
// import { fetchData } from "./ReactRedux/ActionApiOfGetData";
import { jwtDecode } from "jwt-decode";

const OtpPage = () => {
  //   const disptach = useDispatch();
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  const [otp, setOtp] = useState(["", "", "", ""]);
  const navigate = useNavigate();
  const handleOtp = (index, value) => {
    // Update the OTP state when the user enters the OTP
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Automatically move focus to the next input
    const nextIndex = index + 1;
    if (nextIndex < 4 && value !== "" && !isNaN(value)) {
      inputRefs[nextIndex].current.focus();
    }
  };

  const handleKeyUp = (index, e) => {
    const key = e.key.toLowerCase();
    if (key === "backspace" || key === "delete") {
      // Clear the current input and move focus to the previous input
      handleOtp(index, "");
      const prevIndex = index - 1;
      if (prevIndex >= 0) {
        inputRefs[prevIndex].current.focus();
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(
        "https://ruby-important-panther.cyclic.app/UserFetchData"
      );
      const userData = response.data;
      const enteredOtp = otp.join("");
      const enteredOtpNumber = parseInt(enteredOtp, 10);
      const isValidOtp = userData.find(
        ({ userotp }) => userotp === enteredOtpNumber
      );

      if (isValidOtp) {
        const userId = isValidOtp._id;
        const newOtp = "";
        const res = await axios.post(
          "https://ruby-important-panther.cyclic.app/updateotp",
          JSON.stringify({ userId, newOtp }),
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        );

        if (res.data && res.data.token) {
          // Store the token in a cookie with a 1-day expiration (adjust as needed)
          const expirationDate = new Date();
          expirationDate.setMonth(expirationDate.getMonth() + 1);

          Cookies.set("token", res.data.token, {
            expires: expirationDate,
            path: "/"
          });
          toast.success("Data submitted successfully!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
          const token = Cookies.get("token");
          const value = jwtDecode(token);
          // console.log(value, "id btao id btao");
          if (value) {
            // disptach(fetchData(value._id));
          }
          setTimeout(() => {
            navigate("/");
          }, 2000);
        }
      } else {
        toast.error("Entered OTP is invalid", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
    } catch (error) {
      toast.error("Entered OTP is invalid", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      console.error("Error fetching data:", error);
    }
    setOtp(["", "", "", ""]);
  };
  return (
    <>
      <Container>
        <Grid
          container
          style={{
            backgroundImage: `url(${loginIn})`,
            backgroundSize: "85% 100%",
            backgroundRepeat: "no-repeat",
            paddingTop: "10px",
            backgroundPosition: "center center"
          }}
        >
          <Grid xs={2}></Grid>
          <Grid xs={8}>
            <div style={{ marginTop: "10vh", textAlign: "center" }}>
              <p
                style={{
                  //   marginBottom: "0px",
                  //   marginTop: "0px",
                  color: "white",
                  fontSize: "18px",
                  fontWeight: "bold"
                }}
              >
                OtpPage
              </p>
              <form onSubmit={handleSubmit}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {Array.from({ length: 4 }, (_, index) => (
                    <input
                      key={index}
                      ref={inputRefs[index]}
                      style={{
                        width: "2rem",
                        height: "2rem",
                        margin: "0.5rem",
                        textAlign: "center",
                        fontSize: "1rem"
                      }}
                      name={`otP${index}`}
                      type="text"
                      inputMode="numeric"
                      maxLength="1"
                      value={otp[index]}
                      onChange={(e) => handleOtp(index, e.target.value)}
                      onKeyUp={(e) => handleKeyUp(index, e)}
                      tabIndex={index + 1}
                    />
                  ))}
                </div>
                <button
                  type="submit"
                  name="otp_ver"
                  style={{
                    margin: "20px",
                    backgroundColor: "green",
                    borderRadius: "6px",
                    padding: "5px 15px",
                    color: "#fff"
                  }}
                >
                  Submit
                </button>
              </form>
              <ToastContainer />
            </div>
          </Grid>
          <Grid xs={2}></Grid>
        </Grid>
      </Container>
    </>
  );
};

export default OtpPage;
