import React, { useEffect, useState } from "react";
import wholebg from "../../image/icons/graphics (1)/wholebg.png";
import styless from "./Designer.module.css";
// import WalletIcon from "@mui/icons-material/Wallet";
// import walletimg from "../../image/icons/graphics (1)/walleticon.png";
import button from "../../image/icons/button.png";
// import btn from ../../image/icons/button.png
import upiimg from "../../image/icons/graphics (1)/upi.webp";
import bankimg from "../../image/icons/graphics (1)/bankcard3.webp";
import innerimg from "../../image/icons/graphics (1)/5.png";
import { Button, Container, Grid, ListItem, ListItemText } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import popup from "../../image/icons/popup-bg.jpg";
import bank from "../../image/icons/2.png";
// import innerimg from "../../image/icons/graphics (1)/5.png";
import axios from "axios";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import {
  BankDeatilsOfUserget,
  UpiDeatilsOfUserget,
  fetchData
} from "../../RaectRedux/ActionApiOfData";
import { SyncLoader } from "react-spinners";

const Withdraw = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [withdrawalAmount, setWithdrawalAmount] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [acntdetails, setAcntdetails] = useState({});
  // console.log(acntdetails, "acntdetails");
  const navigate = useNavigate();
  // upi form functionality detals start

  const [loaded, setLoaded] = useState(false);
  const a = [wholebg, upiimg, innerimg, bankimg];
  useEffect(() => {
    const loadImage = (url) => {
      return new Promise((resolve) => {
        const image = new Image();
        image.src = url;

        image.onload = () => {
          resolve();
        };
        if (image.complete) {
          resolve();
        }
      });
    };

    const loadImages = async () => {
      // Load regular images
      const regularImageLoadPromises = a.map((img) => loadImage(img));

      try {
        await Promise.all(regularImageLoadPromises).then(() => {
          setLoaded(true);
        });
      } catch (error) {
        console.error("Image loading error:", error);
      }
    };

    loadImages();
  }, []);

  const [formdata, setFormdata] = useState({
    holdername: "",
    upiaddress: "",
    data: new Date().toISOString()
  });

  const jwtyoken = Cookies.get("token");
  const bycrypt = jwtDecode(jwtyoken);
  const bycrpytid = bycrypt._id;
  const dispatch = useDispatch();
  // console.log
  useEffect(() => {
    dispatch(fetchData(bycrpytid));
    dispatch(UpiDeatilsOfUserget(bycrpytid));
    dispatch(BankDeatilsOfUserget(bycrpytid));
  }, [bycrpytid, dispatch]);

  // const navigate = useNavigate();
  const Userid = bycrypt._id;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormdata((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (!formdata.holdername || !formdata.upiaddress) {
      toast.error("All fields are required", {
        position: toast.POSITION.TOP_CENTER
      });
      return;
    }
    try {
      const response = await axios.post(
        "https://ruby-important-panther.cyclic.app/UpiDeatilsOfUser",
        { upidetailsdata: formdata, userid: Userid },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      if (response.status >= 200 && response.status < 300) {
        console.log("Form data submitted successfully");
        toast.success("Successfully submitted details", {
          position: toast.POSITION.TOP_CENTER
        });
        navigate("withdraw");
      } else {
        console.error("Failed to submit form data");
        toast.error("Failed to submit form data", {
          position: toast.POSITION.TOP_CENTER
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error("An error occurred", {
        position: toast.POSITION.TOP_CENTER
      });
    }
  };

  // upi form details end

  const [formdatabank, setformdatabank] = useState({
    holderNameuser: "",
    ifscCode: "",
    bankName: "",
    email: "",
    accountNumber: "",
    data: new Date().toISOString()
  });
  const handleChangebank = (e) => {
    const { name, value } = e.target;
    setformdatabank((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };
  const handleFormSubmitbank = async (event) => {
    console.log(formdatabank);
    event.preventDefault();

    if (
      !formdatabank.accountNumber ||
      !formdatabank.holderNameuser ||
      !formdatabank.ifscCode ||
      !formdatabank.bankName ||
      !formdatabank.email
    ) {
      toast.error("All fields are required", {
        position: toast.POSITION.TOP_CENTER
      });
      return;
    }
    try {
      const response = await axios.post(
        "https://ruby-important-panther.cyclic.app/BankDeatilsOfUser",
        { bandatadetails: formdatabank, userid: Userid },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );

      if (response.status >= 200 && response.status < 300) {
        console.log("Form data submitted successfully");
        toast.success("Successfully submitted details", {
          position: toast.POSITION.TOP_CENTER
        });
        navigate("withdraw");
      } else {
        console.error("Failed to submit form data");
        toast.error("Failed to submit form data", {
          position: toast.POSITION.TOP_CENTER
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error("An error occurred", {
        position: toast.POSITION.TOP_CENTER
      });
    }
  };

  // const [showvalue, setShowvalue] = useState("");
  const data = useSelector((state) => state.add.api1Data);
  const data2 = useSelector((state) => state.add.api2Data);
  const data3 = useSelector((state) => state.add.api3Data);

  // console.log(data[0].Bankdetailsdataonly[0].accountNumber, "data,data");
  const handleOptionChange = (event) => {
    const val = event.target.value;
    // console.log(event.target.value);
    setSelectedOption(event.target.value);
    if (data.length > 0) {
      const bankAccountNumber =
        parseFloat(data[0]?.Bankdetailsdataonly[0]?.accountNumber) || 0;

      const upiDetailsData =
        parseFloat(data[0]?.Upidetailsdataonly[0]?.upiaddress) || 0;

      // Check conditions and set the state accordingly
      if (val === "bank") {
        setAccountNumber(`${bankAccountNumber}`);
        setAcntdetails(data[0].Bankdetailsdataonly[0]);
      } else if (val === "upi") {
        setAccountNumber(`${upiDetailsData}`);
        setAcntdetails(data[0].Upidetailsdataonly[0]);
      } else {
        setAccountNumber(""); // Both are empty
      }
    }
  };
  const walletAmount = data.length > 0 ? parseFloat(data[0].wallet) || 0 : 0;
  const handleSubmit = async (event) => {
    event.preventDefault();
    const bankAccountinfo =
      parseFloat(data[0]?.Bankdetailsdataonly[0]?.accountNumber) || 0;
    const upiDetailsinfo =
      parseFloat(data[0]?.Upidetailsdataonly[0]?.upiaddress) || 0;
    const enteredAmount = parseFloat(withdrawalAmount);
    // console.log(acntdetails);
    if (walletAmount <= 1000) {
      toast.error("You need a minimum balance of 1000 to withdraw", {
        position: toast.POSITION.TOP_CENTER
      });
      return;
    }

    if (!bankAccountinfo && !upiDetailsinfo) {
      // Show toast alert if no details are available
      toast.error("Please fill in bank details", {
        position: toast.POSITION.TOP_CENTER
      });
      return;
    }

    if (
      isNaN(enteredAmount) ||
      enteredAmount <= 0 ||
      enteredAmount > walletAmount
    ) {
      toast.error("Please enter a valid amount", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      return;
    }
    const userId = bycrypt._id;
    // const userName = bycrypt.username;
    const amount = walletAmount - enteredAmount;
    try {
      const updateWalletEndpoint =
        "https://ruby-important-panther.cyclic.app/withdrow";

      // Make an API call to update the wallet
      const response = await axios.post(updateWalletEndpoint, {
        ...acntdetails,
        amount,
        userId
        // status
        // currentDate
      });

      if (response.status === 200) {
        // Handle successful wallet update
        toast.success("Withdrawal request submitted", {
          position: toast.POSITION.TOP_CENTER
        });
        navigate("/");
        dispatch(fetchData(userId));
        // Additional actions after successful withdrawal, if needed
      } else {
        // Handle unsuccessful wallet update
        toast.error("Failed to update wallet", {
          position: toast.POSITION.TOP_CENTER
        });
      }
    } catch (error) {
      // Handle API call error
      console.error("Error updating wallet:", error);
      toast.error("An error occurred while processing the withdrawal", {
        position: toast.POSITION.TOP_CENTER
      });
    }
  };

  const formatAccountNumber = (accountNumber) => {
    // Assuming accountNumber is a string
    const visibleDigits = accountNumber.slice(0, -2);
    const hiddenDigits = accountNumber.slice(-2);
    return `${visibleDigits} ●●${hiddenDigits}`;
  };
  // const handleSubmit = () => {
  // Check if any details are available
  // if (!data2.upiAddress && !data3.accountNumber) {
  //   // Show toast alert if no details are available
  //   toast.error("Please fill in bank details", {
  //     position: toast.POSITION.TOP_CENTER
  //   });
  //   return;
  // }

  // Proceed with your logic if any details are available
  // You can add additional logic here

  // For example, you can redirect to another page
  // history.push('/your-target-page');

  // Or perform any other actions you need
  // };

  const obj = {
    withdraw: "withdraw",
    Upi: "Upi",
    Bank: "Bank",
    History: "History"
  };

  const obj1 = {
    Recharge: "Recharge",
    withdrawHistory: "withdrawHistory",
    Other: "Other"
  };
  const [activeSectionKeysec, setActiveSectionKeysec] = useState(
    Object.keys(obj1)[0]
  );
  const genratebody2 = () => {
    switch (obj1[activeSectionKeysec]) {
      case obj1.Recharge:
        return (
          <>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias,
            temporibus aliquam ipsam neque distinctio sapiente voluptate
            recusandae. Voluptatum eos eum, esse eveniet porro pariatur unde
            molestias! Qui cumque quidem corporis?
          </>
        );
      case obj1.withdrawHistory:
        return (
          <>
            <div
              className="table"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <table style={{ width: "100%", tableLayout: "fixed" }}>
                <thead>
                  <tr>
                    <th style={{ width: "20%", color: "#dd6800" }}>hiii</th>
                    <th style={{ width: "20%", color: "#dd6800" }}>hiii</th>
                    <th style={{ width: "20%", color: "#dd6800" }}>hiii</th>
                    <th style={{ width: "20%", color: "#dd6800" }}>hiii</th>
                    <th style={{ width: "20%", color: "#dd6800" }}>hiii</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{
                        width: "20%",
                        textAlign: "center",
                        color: "#cd8264"
                      }}
                    >
                      hello
                    </td>
                    <td
                      style={{
                        width: "20%",
                        textAlign: "center",
                        color: "#cd8264"
                      }}
                    >
                      hello
                    </td>
                    <td
                      style={{
                        width: "20%",
                        textAlign: "center",
                        color: "#cd8264"
                      }}
                    >
                      hello
                    </td>
                    <td
                      style={{
                        width: "20%",
                        textAlign: "center",
                        color: "#cd8264"
                      }}
                    >
                      hello
                    </td>
                    <td
                      style={{
                        width: "20%",
                        textAlign: "center",
                        color: "#cd8264"
                      }}
                    >
                      hello
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        );
      case obj1.Other:
        return (
          <>
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            MolestiasLorem ipsum dolor sit amet consectetur adipisicing elit.
            Molestias, temporibus aliquam ipsam neque distinctio sapiente
            voluptate recusandae. Voluptatum eos eum, esse eveniet porro
            pariatur unde molestias! Qui cumque quidem corporis?, temporibus
            aliquam ipsam neque distinctio sapiente voluptate recusandae.
            Voluptatum eos eum, esse eveniet porro pariatur unde molestias! Qui
            cumque quidem corporis?Lorem ipsum dolor sit amet consectetur
            adipisicing elit. Molestias, temporibus aliquam ipsam neque
            distinctio sapiente voluptate recusandae. Voluptatum eos eum, esse
            eveniet porro pariatur unde molestias! Qui cumque quidem
            corporis?Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Molestias, temporibus aliquam ipsam neque distinctio sapiente
            voluptate recusandae. Voluptatum eos eum, esse eveniet porro
            pariatur unde molestias! Qui cumque quidem corporis?Lorem ipsum
          </>
        );
      default:
        return null;
    }
  };
  const [activeSectionKey, setActiveSectionKey] = useState(Object.keys(obj)[0]);
  const genratebody1 = () => {
    switch (obj[activeSectionKey]) {
      case obj.History:
        return (
          <Container>
            <Grid container>
              {Object.keys(obj1)?.map((item) => (
                <Grid
                  xs={4}
                  style={{
                    marginTop: "1px",
                    textAlign: "center",
                    justifyContent: "center",
                    color: "#e35b04"
                  }} // Set your desired font size, e.g., "16px"
                  key={item}
                  className={`${styless.section1} ${
                    activeSectionKey === item ? styless.activee1 : ""
                  }`}
                  onClick={() => setActiveSectionKeysec(item)}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      color: "#ff7937",
                      fontWeight: "500"
                    }}
                  >
                    {item}
                  </span>
                </Grid>
              ))}
            </Grid>
            <Grid container>
              <Grid
                xs={12}
                style={{
                  height: "170px",
                  overflow: "auto"
                }}
              >
                {genratebody2()}
              </Grid>
            </Grid>
          </Container>
        );
      case obj.Upi:
        return (
          <Container
          // style={{
          //   backgroundImage: `url(${popup})`,
          //   backgroundSize: "100% 100%",
          //   backgroundRepeat: "no-repeat",
          //   backgroundPosition: "center center",
          //   backgroundAttachment: "fixed", // Add this line
          //   width: "100%",
          //   height: "100%"
          // }}
          >
            <Grid
              container
              style={{
                // paddingTop: "0px",

                // paddingBottom: "0px",

                backgroundAttachment: "fixed", // Add this line
                width: "100%",
                height: "70%"
              }}
            >
              <Grid
                xs={12}
                style={
                  {
                    // backgroundRepeat: "no-repeat",
                    // paddingTop: "30px",
                    // backgroundPosition: "center"
                  }
                }
              >
                <form onSubmit={handleFormSubmit}>
                  <p
                    style={{
                      marginBottom: "0px",
                      marginTop: "15px",
                      color: "#cd8264"
                    }}
                  >
                    <span style={{ fontSize: "14px", paddingRight: "5px" }}>
                      UPI address
                    </span>
                    <input
                      placeholder="Upi Address"
                      type="text"
                      name="upiaddress"
                      value={formdata.upiaddress}
                      onChange={handleChange}
                      style={{
                        borderRadius: "10px",
                        color: "white",
                        textAlign: "center",
                        padding: "2px 5px",
                        backgroundColor: "#310e0e"
                      }}
                    />
                  </p>
                  <p
                    style={{
                      marginBottom: "0px",
                      marginTop: "15px",
                      color: "#cd8264"
                    }}
                  >
                    <span style={{ fontSize: "14px", paddingRight: "5px" }}>
                      Holder Name
                    </span>
                    <input
                      placeholder="Upi Address"
                      type="text"
                      name="holdername"
                      value={formdata.holdername}
                      onChange={handleChange}
                      style={{
                        borderRadius: "10px",
                        color: "white",
                        textAlign: "center",
                        padding: "2px 5px",
                        backgroundColor: "#310e0e"
                      }}
                    />
                  </p>
                  <p
                    style={{
                      marginBottom: "0px",
                      marginTop: "0px",
                      textAlign: "center"
                    }}
                  >
                    <Button
                      type="submit"
                      style={{
                        marginTop: "10px",
                        marginBottom: "0px",
                        // fontSize: "15px",
                        borderRadius: "10px",
                        width: "30%",
                        fontWeight: "bold",
                        // padding: "5px 5px",
                        color: "white",
                        backgroundImage: `url(${button})`,
                        backgroundSize: "90% 97%",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat"
                      }}
                    >
                      Submit
                    </Button>
                  </p>
                </form>
              </Grid>
            </Grid>
          </Container>
        ); // if key is "info" then return "personInfo object"
      case obj.withdraw:
        return (
          <form onSubmit={handleSubmit}>
            <Grid container style={{ marginLeft: "15px" }}>
              <Grid
                xs={12}
                style={{
                  backgroundImage: `url(${innerimg})`,
                  backgroundSize: "100% 95%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center"
                  // height: "120px"
                }}
              >
                <Grid container>
                  <Grid xs={6} style={{ textAlign: "center" }}>
                    <p
                      style={{
                        marginTop: "0px",
                        marginBottom: "0px",
                        fontSize: "10px",
                        paddingTop: "12px",
                        color: "#cd8264"
                      }}
                    >
                      Total Balance
                    </p>
                    <p
                      style={{
                        marginTop: "0px",
                        marginBottom: "0px",
                        borderRadius: "5px",
                        width: "60%",
                        padding: "3px 4px",
                        fontSize: "10px",
                        backgroundColor: "#431313",
                        color: "#ffc7b1"
                      }}
                    >
                      {walletAmount}
                    </p>
                  </Grid>
                  <Grid xs={6} style={{ textAlign: "center" }}>
                    <p
                      style={{
                        marginTop: "0px",
                        marginBottom: "0px",
                        fontSize: "10px",
                        paddingTop: "12px",
                        color: "#cd8264"
                      }}
                    >
                      Withdraw
                    </p>
                    <p
                      style={{
                        marginTop: "0px",
                        marginBottom: "0px",
                        borderRadius: "5px",
                        width: "60%",
                        padding: "1px 4px",
                        fontSize: "10px",
                        backgroundColor: "#431313",
                        color: "#ffc7b1"
                      }}
                    >
                      {walletAmount}
                    </p>
                  </Grid>
                  <Grid xs={12} style={{ textAlign: "center" }}>
                    <p
                      style={{
                        marginTop: "0px",
                        marginBottom: "0px",
                        fontSize: "10px",
                        paddingTop: "1px",
                        color: "#cd8264"
                      }}
                    >
                      Amount
                    </p>

                    <p
                      style={{
                        marginTop: "0px",
                        marginBottom: "0px",
                        // borderRadius: "5px",
                        width: "100%",
                        textAlign: "center",
                        // padding: "3px 4px",
                        // fontSize: "11px",
                        // backgroundColor: "#431313",
                        color: "#ffc7b1"
                      }}
                    >
                      <input
                        type="text"
                        style={{
                          backgroundColor: "#431313",
                          borderRadius: "5px",
                          color: "#cd8264"
                        }}
                        value={withdrawalAmount}
                        onChange={handleAmountChange}
                      />
                    </p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  backgroundImage: `url(${innerimg})`,
                  backgroundSize: "92% 75%",
                  backgroundRepeat: "no-repeat",
                  paddingTop: "30px",
                  backgroundPosition: "center",
                  height: "120px"
                }}
              >
                {/* <Grid xs={7} style={{ height: "20px" }}>
            <p
              style={{
                marginTop: "0px",
                marginBottom: "0px",
                borderRadius: "5px",
                width: "60%",
                textAlign: "center",
                height: "20px",
                padding: "1px 4px",
                fontSize: "11px",
                backgroundColor: "#431313",
                color: "#ffc7b1"
              }}
            >
              {/* {showvalue} 
            </p>
          </Grid> */}
                <Grid container style={{ height: "20px" }}>
                  <Grid item xs={6}>
                    <p
                      style={{
                        marginTop: "0px",
                        marginBottom: "0px",
                        // borderRadius: "5px",
                        width: "60%",
                        marginLeft: "25px",
                        textAlign: "center",
                        color: "#ffc7b1"
                      }}
                    >
                      <input
                        type="text"
                        style={{
                          backgroundColor: "#431313",
                          borderRadius: "5px",
                          color: "#cd8264"
                        }}
                        value={formatAccountNumber(accountNumber)}
                        onChange={handleAmountChange}
                      />
                    </p>
                  </Grid>
                  {/* <Grid item xs={3}>
                    <p
                      style={{
                        textAlign: "center",
                        marginBottom: "0px",
                        color: "white",
                        marginTop: "0px",
                        paddingTop: "0px"
                      }}
                    ></p>
                  </Grid> */}
                  <Grid item xs={6}>
                    <Grid container style={{ justifyContent: "center" }}>
                      <Grid item xs={3}>
                        <label>
                          <p
                            style={{
                              textAlign: "center",
                              marginBottom: "0px",
                              color: "white",
                              marginTop: "0px",
                              paddingTop: "0px"
                            }}
                          >
                            <input
                              type="radio"
                              value="upi"
                              checked={selectedOption === "upi"}
                              onChange={handleOptionChange}
                            />
                            <img
                              src={upiimg}
                              width="55px"
                              style={{ marginTop: "10px" }}
                              alt=""
                            />
                          </p>
                        </label>
                      </Grid>

                      <Grid item xs={3}>
                        <label>
                          <p
                            style={{
                              textAlign: "center",
                              marginBottom: "0px",
                              color: "white",
                              marginTop: "0px",
                              paddingTop: "0px"
                            }}
                          >
                            <input
                              type="radio"
                              value="bank"
                              checked={selectedOption === "bank"}
                              onChange={handleOptionChange}
                            />
                            <img src={bankimg} width="55px" alt="" />
                          </p>
                        </label>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* <p
            style={{
              marginBottom: "0px",
              borderRadius: "5px",
              textAlign: "center"
            }}
          >
            <img src={button} width="" alt="" />
          </p> */}
                </Grid>
                <Grid xs={12} style={{ textAlign: "center" }}>
                  <Button
                    type="submit"
                    style={{
                      marginTop: "0px",
                      marginBottom: "0px",
                      // fontSize: "15px",
                      borderRadius: "10px",
                      width: "25%",
                      fontWeight: "bold",
                      padding: "2px 2px",
                      color: "white",
                      backgroundImage: `url(${button})`,
                      backgroundSize: "90% 97%",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat"
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        ); // if key is "info" then return "personInfo object"
      case obj.Bank:
        return (
          <Container
          // style={{
          //   backgroundImage: `url(${popup})`,
          //   backgroundSize: "100% 100%",
          //   // paddingTop: "52px",
          //   backgroundRepeat: "no-repeat",
          //   // paddingBottom: "30px",
          //   backgroundPosition: "center center",
          //   backgroundAttachment: "fixed",
          //   width: "100%",
          //   height: "100%"
          // }}
          >
            <form onSubmit={handleFormSubmitbank}>
              <Grid
                container
                style={
                  {
                    // backgroundImage: `url(${bank})`,
                    // backgroundSize: "74% 94%",
                    // paddingTop: "23px",
                    // paddingBottom: "5px",
                    // backgroundRepeat: "no-repeat",
                    // backgroundPosition: "center center",
                    // backgroundAttachment: "fixed", // Add this line
                    // width: "100%",
                    // height: "90%"
                  }
                }
              >
                <p
                  style={{
                    marginBottom: "0px",
                    marginTop: "0px",
                    paddingBottom: "0px",
                    textAlign: "center",
                    color: "white",
                    width: "100%"
                  }}
                >
                  <div style={{ marginBottom: "0px", marginTop: "0px" }}>
                    <span
                      style={{
                        fontSize: "12px",
                        color: "#df8e8e",
                        padding: "0px 4px"
                      }}
                    >
                      Account Number
                    </span>
                    <input
                      color="white"
                      type="text"
                      name="accountNumber"
                      value={formdatabank.accountNumber}
                      onChange={handleChangebank}
                      style={{
                        borderRadius: "10px",
                        width: "80%",
                        padding: "2px 4px",
                        backgroundColor: "#310e0e",
                        color: "white"
                      }}
                    />
                  </div>
                </p>
                <Grid
                  xs={12}
                  style={
                    {
                      // backgroundImage: `url(${innerimg})`,
                      // backgroundSize: "100% 100%",
                      // backgroundRepeat: "no-repeat",
                      // paddingTop: "30px",
                      // backgroundPosition: "center",
                      // height: "140px"
                    }
                  }
                >
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div
                          style={{
                            marginTop: "0px",
                            marginBottom: "0px",
                            fontSize: "12px",
                            paddingTop: "2px",
                            color: "#cd8264"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "12px",
                              color: "#df8e8e",
                              padding: "0px 4px"
                            }}
                          >
                            UserName
                            <input
                              color="white"
                              type="text"
                              name="holderNameuser"
                              value={formdatabank.holderNameuser}
                              onChange={handleChangebank}
                              style={{
                                borderRadius: "10px",
                                color: "white",
                                padding: "2px 4px",
                                width: "90%",
                                backgroundColor: "#310e0e"
                              }}
                            />
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div
                          style={{
                            marginTop: "0px",
                            marginBottom: "0px",
                            fontSize: "12px",
                            paddingTop: "2px",
                            color: "#cd8264"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "12px",
                              color: "#df8e8e",
                              padding: "0px 4px"
                            }}
                          >
                            IFSC
                            <input
                              type="text"
                              color="white"
                              name="ifscCode"
                              value={formdatabank.ifscCode}
                              onChange={handleChangebank}
                              style={{
                                borderRadius: "10px",
                                padding: "2px 4px",
                                color: "white",
                                width: "90%",
                                backgroundColor: "#310e0e"
                              }}
                            />
                          </span>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={6}>
                        <div
                          style={{
                            marginTop: "10px",
                            marginBottom: "0px",
                            fontSize: "12px",
                            paddingTop: "2px",
                            color: "#cd8264"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "12px",

                              color: "#df8e8e",
                              padding: "0px 4px"
                            }}
                          >
                            BankName
                            <input
                              color="white"
                              type="text"
                              name="bankName"
                              value={formdatabank.bankName}
                              onChange={handleChangebank}
                              style={{
                                borderRadius: "10px",
                                width: "90%",
                                color: "white",
                                padding: "2px 4px",
                                backgroundColor: "#310e0e"
                              }}
                            />
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div
                          style={{
                            marginTop: "10px",
                            marginBottom: "0px",
                            fontSize: "12px",
                            paddingTop: "2px",
                            color: "#cd8264"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "12px",
                              color: "#df8e8e",
                              padding: "0px 4px"
                            }}
                          >
                            Email
                            <input
                              type="text"
                              color="white"
                              name="email"
                              value={formdatabank.email}
                              onChange={handleChangebank}
                              style={{
                                borderRadius: "10px",
                                color: "white",
                                width: "90%",
                                padding: "2px 4px",
                                backgroundColor: "#310e0e"
                              }}
                            />
                          </span>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={12} style={{ textAlign: "center", marginTop: "5px" }}>
                  <Button
                    type="submit"
                    style={{
                      marginTop: "0px",
                      marginBottom: "0px",

                      borderRadius: "10px",
                      width: "25%",
                      fontWeight: "bold",
                      padding: "5px 5px",
                      color: "white",
                      backgroundImage: `url(${button})`,
                      backgroundSize: "90% 97%",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat"
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Container>
        ); // if key is "info" then return "personInfo object" // if key is "WorkEx" then return "personInfo object"
      default:
        return null;
    }
  };
  const handleAmountChange = (event) => {
    const value = event.target.value;
    console.log("New value:", value);
    setWithdrawalAmount(value);
  };

  // useEffect(() => {
  //   // Check if both upiAddress and accountNumber are available
  //   if (data2.upiAddress && data3.accountNumber) {
  //     setShowvalue(data2.upiAddress); // Set showvalue based on your logic
  //     setActiveSectionKey(Object.keys(obj)[0]);
  //   }

  //   // Check if only upiAddress is available
  //   if (data2.upiAddress) {
  //     setShowvalue(data2.upiAddress); // Set showvalue based on your logic
  //     setActiveSectionKey(Object.keys(obj)[0]);
  //   }

  //   // Check if only accountNumber is available
  //   if (data3.accountNumber) {
  //     setShowvalue(data3.accountNumber); // Set showvalue based on your logic
  //     setActiveSectionKey(Object.keys(obj)[1]);
  //   }
  // }, [data2.upiAddress, data3.accountNumber]);

  return (
    <>
      {loaded ? (
        <Container
          style={{
            backgroundImage: `url(${wholebg})`,
            backgroundSize: "100% 100%",
            paddingTop: "50px",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            // backgroundAttachment: "fixed", // Add this line
            width: "100%",
            height: "100%"
          }}
        >
          <Grid container>
            <Grid xs={3}>
              {Object.keys(obj)?.map((item) => (
                <ListItem
                  style={{
                    marginTop: "1px",
                    textAlign: "center",
                    justifyContent: "center",
                    color: "#e35b04"
                  }} // Set your desired font size, e.g., "16px"
                  key={item}
                  className={`${styless.section} ${
                    activeSectionKey === item ? styless.activee : ""
                  }`}
                  onClick={() => setActiveSectionKey(item)}
                >
                  <span
                    style={{
                      fontSize: "14px"
                    }}
                  >
                    {item}
                  </span>
                </ListItem>
              ))}
            </Grid>
            <Grid xs={9}>{genratebody1()}</Grid>
          </Grid>
          <ToastContainer />
        </Container>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Adjust the height as needed
            textAlign: "center",
            color: "#bd5c0c"
          }}
        >
          <SyncLoader size={12} color={"#bd5c0c"} />
        </div>
      )}
    </>
  );
};

export default Withdraw;
