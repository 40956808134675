import {
  Box,
  Container,
  Grid
  // List,
  // ListItem,
  // ListItemText,
  // Typography
} from "@mui/material";
import React from "react";
import daily from "../../image/icons/daily bonus/daily bonus/daily.png";
import yellow from "../../image/icons/daily bonus/daily bonus/coinboinusyellow.png";
import green from "../../image/icons/daily bonus/daily bonus/coinbonusgreen.png";
// import referwin
import ClearIcon from "@mui/icons-material/Clear";
import { Link } from "react-router-dom";

const Bonus = ({ onClose }) => {
  const BonusData = [
    {
      GreenImg: green,
      text: "Day 1"
    },
    {
      text: "Day 2"
    },
    {
      text: "Day 3"
    },
    {
      text: "Day 4"
    },
    {
      text: "Day 5"
    },
    {
      text: "Day 6"
    },
    {
      text: "Day 7"
    }
  ];
  return (
    <>
      <Container
        style={{
          backgroundImage: `url(${daily})`, // Specify the path to your image
          backgroundSize: "cover", // Adjust as needed
          backgroundRepeat: "no-repeat",
          width: "90%",
          marginTop: "1px",
          borderRadius: "5px",
          height: "90%",
          paddingLeft: "0px",
          paddingRight: "0px",
          paddingTop: "5px",
          paddingBottom: "5px",
          textAlign: "center"
        }}
      >
        <Grid
          container
          style={{
            width: "82%",
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            justifyContent: "center"
          }}
        >
          <Box sx={{ display: "block" }}>
            <p
              style={{
                fontSize: "17px",
                textAlign: "center",
                color: "wheat",
                marginBottom: "-4px"
              }}
            >
              Daily Bonus
            </p>
            <p
              style={{
                fontSize: "13px",
                textAlign: "center",
                color: "wheat",
                marginTop: "6px"
              }}
            >
              Higher VIP,higher bonus as goes
            </p>
          </Box>
          <Box
            sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
          >
            {BonusData.map((data) => (
              <Box sx={{ position: "relative" }}>
                <img
                  src={data.GreenImg ? data.GreenImg : yellow}
                  alt={data.text || ""}
                  style={{ width: "80px", margin: "0px 8px" }}
                />
                <p
                  style={{
                    position: "absolute",
                    bottom: "4px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    color: "wheat"
                  }}
                >
                  {data.text}
                </p>
              </Box>
            ))}
          </Box>
          <Link to="">
            <ClearIcon
              onClick={onClose}
              sx={{
                position: "absolute",
                top: "18px",
                right: "26px",
                color: "white"
              }}
            ></ClearIcon>
          </Link>
        </Grid>
      </Container>
    </>
  );
};

export default Bonus;
