import { Container, Grid, List, ListItem, ListItemText } from "@mui/material";
import React, { useState } from "react";
import "./popups.css";
import styless from "./Designer.module.css";
import img from "../../image/icons/popup/background.jpg";
// import bg_img from "../../image/icons/popup/7.png";
import bg from "../../image/icons/refer earn/2.webp";
import bg_img1 from "../../image/icons/popup/Screenshot 2024-01-06 at 11.05.20 AM.png";
import { Close } from "@mui/icons-material";
import { useEffect } from "react";
import { SyncLoader } from "react-spinners";

const Popup = ({ onClose }) => {
  const a = [img, bg, bg_img1];
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const loadImage = (url) => {
      return new Promise((resolve) => {
        const image = new Image();
        image.src = url;

        image.onload = () => {
          resolve();
        };
        if (image.complete) {
          resolve();
        }
      });
    };

    const loadImages = async () => {
      // Load regular images
      const regularImageLoadPromises = a.map((img) => loadImage(img));

      try {
        await Promise.all(regularImageLoadPromises).then(() => {
          setLoaded(true);
        });
      } catch (error) {
        console.error("Image loading error:", error);
      }
    };

    loadImages();
  }, []);

  const obj = {
    Bonus: "Deposite Bonus",
    Lucky: "Lucky Player",
    Telegram: "Telegram",
    Recharge: "Recharge",
    Monday_Bonus: "Monday Bonus",
    Refer_Win: "Refer & Win"
  };
  const [activeSectionKey, setActiveSectionKey] = useState(Object.keys(obj)[0]);

  // personal information part start
  const personInfo = (
    <div className="row" style={{ position: "relative", width: "100%" }}>
      <img
        className="popup-Img"
        src={bg_img1}
        width="95%"
        style={{
          display: "block",
          margin: "auto",
          height: "180px",
          width: "96%"
        }}
        alt=""
      />
    </div>
  );
  //personal information part end

  //workinformation part start
  const workinfo = (
    <div className="row" style={{ position: "relative", width: "100%" }}>
      <img
        className="popup-Img"
        src={img}
        width="95%"
        style={{
          display: "block",
          margin: "auto",
          height: "180px",
          width: "96%"
        }}
        alt=""
      />
    </div>
  );
  //workinformation part end

  //education part start

  const education = (
    <div className="row" style={{ position: "relative", width: "100%" }}>
      <img
        className="popup-Img"
        src={bg_img1}
        width="95%"
        style={{
          display: "block",
          margin: "auto",
          height: "180px",
          width: "96%"
        }}
        alt=""
      />
    </div>
  );
  //education part end

  const welcome = (
    <div className="row" style={{ position: "relative", width: "100%" }}>
      <img
        className="popup-Img"
        src={img}
        width="95%"
        style={{
          display: "block",
          margin: "auto",
          height: "180px",
          width: "96%"
        }}
        alt=""
      />
    </div>
  );

  const year_bonum = (
    <div className="row" style={{ position: "relative", width: "100%" }}>
      <img
        className="popup-Img"
        src={bg_img1}
        width="95%"
        style={{
          display: "block",
          margin: "auto",
          height: "180px",
          width: "96%"
        }}
        alt=""
      />
    </div>
  );

  //   const Monday_Bonus = (
  //     <div className="row" style={{position:"absolute",top:'65%',transform:'translateY(-50%)',width:'92%'}}>
  //       <img src={img} width="90%" style={{display:'block',margin:'auto',height:'180px'}} alt="" />
  //     </div>
  //   );

  //   const Refer_Win = (
  //     <div className="row" style={{position:"absolute",top:'65%',transform:'translateY(-50%)',width:'92%'}}>
  //       <img src={img} width="90%" style={{display:'block',margin:'auto',height:'180px'}} alt="" />
  //     </div>
  //   );
  //   //keyskills part start

  const genratebody1 = () => {
    switch (obj[activeSectionKey]) {
      case obj.Bonus:
        return personInfo; // if key is "info" then return "personInfo object"
      case obj.Lucky:
        return workinfo; // if key is "WorkEx" then return "personInfo object"
      case obj.Telegram:
        return education; // if key is "edu" then return "personInfo object"
      case obj.Recharge:
        return welcome; // if key is "KeySkills" then return "personInfo object"
      case obj.Monday_Bonus:
        return year_bonum; // if key is "info" then return "personInfo object"
      // if key is "WorkEx" then return "personInfo object"
      // if key is "KeySkills" then return "personInfo object"
      default:
        return null;
    }
  };
  return (
    <>
      {loaded ? (
        <Container
          style={{
            backgroundImage: `url(${bg})`, // Specify the path to your image
            backgroundSize: "99% 100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "99%",
            height: "100%",
            paddingLeft: "0px",
            paddingRight: "0px",
            overflow: "auto",
            paddingTop: "0px",
            position: "relative",
            textAlign: "center"
          }}
        >
          <Grid container>
            <Grid xs={12}>
              <Close
                onClick={onClose}
                sx={{
                  position: "absolute",
                  right: "16px",
                  top: "0px",
                  fontSize: "35px",
                  color: "white"
                }}
              />
            </Grid>
            <Grid
              item
              id="leftside"
              xs={3}
              sx={{
                marginTop: "55px",
                overflow: "auto",
                height: "200px"
                // fontSize: "15px"
              }}
            >
              <List
                sx={{
                  paddingTop: "20px",
                  paddingLeft: "10px"
                }}
              >
                {Object.keys(obj)?.map((item) => (
                  <ListItem
                    style={{
                      padding: "0px",
                      marginTop: "1px",
                      height: "35px",
                      fontSize: "12px",
                      textAlign: "center",
                      color: "#e35b04"
                    }} // Set your desired font size, e.g., "16px"
                    key={item}
                    className={`${styless.section} ${
                      activeSectionKey === item ? styless.activee : ""
                    }`}
                    onClick={() => setActiveSectionKey(item)}
                  >
                    <ListItemText className="item" primary={obj[item]} />
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid
              item
              xs={9}
              style={{
                fontSize: "11px",
                marginTop: "78px",
                color: "wheat",
                position: "relative",
                display: "flex",
                justifyContent: "center"
              }}
            >
              {genratebody1()}
            </Grid>
          </Grid>
        </Container>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Adjust the height as needed
            textAlign: "center",
            color: "#bd5c0c"
          }}
        >
          <SyncLoader size={12} color={"#bd5c0c"} />
        </div>
      )}
    </>
  );
};

export default Popup;

// import { Container, Grid, List, ListItem, ListItemText } from "@mui/material";
// import React, { useState } from "react";
// import "./popups.css";
// import styless from "./Designer.module.css";
// import img from "../../image/icons/background.jpg";
// import bg_img from '../../image/icons/popup/popup/7.png'
// import bg_img1 from '../../image/icons/popup/popup/Screenshot 2024-01-06 at 11.05.20 AM.png'
// const Popup = () => {
//   const obj = {
//     Bonus: "Deposite Bonus",
//     Lucky: "Lucky Player",
//     Telegram: "Telegram",
//     Recharge: "Recharge",
//     Monday_Bonus: "Monday Bonus",
//     Refer_Win: "Refer & Win",
//     Refer_Earn: "Refer & Earn",
//     year_bonum: "New Year Bonus",
//     Weekly_bonus: "Weekly Bonus",
//     welcome: "Welcome"
//   };
//   const [activeSectionKey, setActiveSectionKey] = useState(Object.keys(obj)[0]);

//   // personal information part start
//   const personInfo = (
//     <div className="row" style={{position:"absolute",top:'65%',transform:'translateY(-50%)',width:'92%'}}>
//       <img src={bg_img1} width="90%" style={{display:'block',margin:'auto',height:'180px'}} alt="" />
//     </div>
//   );
//   //personal information part end

//   //workinformation part start
//   const workinfo = (
//     <div className="row" style={{position:"absolute",top:'65%',transform:'translateY(-50%)',width:'92%'}}>
//       <img src={img} width="90%" style={{display:'block',margin:'auto',height:'180px'}} alt="" />
//     </div>
//   );
//   //workinformation part end

//   //education part start

//   const education = (
//     <div className="row" style={{position:"absolute",top:'65%',transform:'translateY(-50%)',width:'92%'}}>
//       <img src={bg_img1} width="90%" style={{display:'block',margin:'auto',height:'180px'}} alt="" />
//     </div>
//   );
//   //education part end

//   const welcome = (
//     <div className="row" style={{position:"absolute",top:'65%',transform:'translateY(-50%)',width:'92%'}}>
//       <img src={img} width="90%" style={{display:'block',margin:'auto',height:'180px'}} alt="" />
//     </div>
//   );

//   const year_bonum = (
//     <div className="row" style={{position:"absolute",top:'65%',transform:'translateY(-50%)',width:'92%'}}>
//       <img src={bg_img1} width="90%" style={{display:'block',margin:'auto',height:'180px'}} alt="" />
//     </div>
//   );

//   const Monday_Bonus = (
//     <div className="row" style={{position:"absolute",top:'65%',transform:'translateY(-50%)',width:'92%'}}>
//       <img src={img} width="90%" style={{display:'block',margin:'auto',height:'180px'}} alt="" />
//     </div>
//   );

//   const Refer_Win = (
//     <div className="row" style={{position:"absolute",top:'65%',transform:'translateY(-50%)',width:'92%'}}>
//       <img src={img} width="90%" style={{display:'block',margin:'auto',height:'180px'}} alt="" />
//     </div>
//   );
//   //keyskills part start
//   const keyskills = (
//     <div className="row" style={{position:"absolute",top:'65%',transform:'translateY(-50%)',width:'92%'}}>
//       <img src={bg_img1} width="90%" style={{display:'block',margin:'auto',height:'180px'}} alt="" />
//     </div>
//   );

//   const Refer_Earn = (
//     <div className="row" style={{position:"absolute",top:'65%',transform:'translateY(-50%)',width:'92%'}}>
//       <img src={img} width="90%" style={{display:'block',margin:'auto',height:'180px'}} alt="" />
//     </div>
//   );

//   const Weekly_bonus = (
//     <div className="row" style={{position:"absolute",top:'65%',transform:'translateY(-50%)',width:'92%'}}>
//       <img src={bg_img1} width="90%" style={{display:'block',margin:'auto',height:'180px'}} alt="" />
//     </div>
//   );

//   const genratebody1 = () => {
//     switch (obj[activeSectionKey]) {
//       case obj.Bonus:
//         return personInfo; // if key is "info" then return "personInfo object"
//       case obj.Lucky:
//         return workinfo; // if key is "WorkEx" then return "personInfo object"
//       case obj.Telegram:
//         return education; // if key is "edu" then return "personInfo object"
//       case obj.Recharge:
//         return keyskills; // if key is "KeySkills" then return "personInfo object"
//       case obj.Monday_Bonus:
//         return Monday_Bonus; // if key is "info" then return "personInfo object"
//       case obj.Refer_Win:
//         return Refer_Win; // if key is "WorkEx" then return "personInfo object"
//       case obj.Refer_Earn:
//         return Refer_Earn; // if key is "edu" then return "personInfo object"
//       case obj.year_bonum:
//         return year_bonum; // if key is "KeySkills" then return "personInfo object"
//       case obj.Weekly_bonus:
//         return Weekly_bonus; // if key is "edu" then return "personInfo object"
//       case obj.welcome:
//         return welcome; // if key is "KeySkills" then return "personInfo object"
//       default:
//         return null;
//     }
//   };
//   return (
//     <>
//       <Container
//         style={{
//           // backgroundColor: "#4c1e14",
//           width: "100%",
//           marginTop: "25px",
//           // marginLeft: "0px",
//           // height: "90%",s
//           paddingLeft: "0px",
//           // marginRight: "0px",
//           paddingRight: "0px",
//           backgroundImage:url(${bg_img}),
//           backgroundSize:'cover',
//           backgroundRepeat:'no-repeat'
//         }}
//       >
//         <Grid container sx={{}}>
//           <Grid item xs={3} sx={{marginTop:'55px'}}>
//             <List sx={{paddingTop:'55px',paddingLeft:'10px'}} >
//               {Object.keys(obj)?.map((item) => (
//                 <ListItem
//                   style={{
//                     padding: "0px",
//                     marginTop: "1px",
//                     textAlign: "center",
//                     color: "#e35b04"
//                   }} // Set your desired font size, e.g., "16px"
//                   key={item}
//                   className={`${styless.section} ${
//                     activeSectionKey === item ? styless.activee : ""
//                   }`}
//                   onClick={() => setActiveSectionKey(item)}
//                 >
//                   <ListItemText className="item" primary={obj[item]} />
//                 </ListItem>
//               ))}
//             </List>
//           </Grid>
//           <Grid
//             item
//             xs={9}
//             style={{ fontSize: "11px", marginTop: "5px", color: "wheat",position:'relative',display:'flex',alignItems:'center',justifyContent:'center' }}
//           >
//             {genratebody1()}
//           </Grid>
//         </Grid>
//       </Container>
//     </>
//   );
// };

// export default Popup;

// import { Container, Grid, List, ListItem, ListItemText } from "@mui/material";
// import React, { useState } from "react";
// import "./popups.css";
// import styless from "./Designer.module.css";
// import img from "../../image/icons/background.jpg";
// const Popup = () => {
//   const obj = {
//     Bonus: "Deposite Bonus",
//     Lucky: "Lucky Player",
//     Telegram: "Telegram",
//     Recharge: "Recharge",
//     Monday_Bonus: "Monday Bonus",
//     Refer_Win: "Refer & Win",
//     Refer_Earn: "Refer & Earn",
//     year_bonum: "New Year Bonus",
//     Weekly_bonus: "Weekly Bonus",
//     welcome: "Welcome"
//   };
//   const [activeSectionKey, setActiveSectionKey] = useState(Object.keys(obj)[0]);

//   // personal information part start
//   const personInfo = (
//     <div className="row">
//       <img src={img} width="100%" alt="" />
//     </div>
//   );
//   //personal information part end

//   //workinformation part start
//   const workinfo = (
//     <div className="row">
//       <img src={img} width="100%" alt="" />
//     </div>
//   );
//   //workinformation part end

//   //education part start

//   const education = (
//     <div className="row">
//       <img src={img} width="100%" alt="" />
//     </div>
//   );
//   //education part end

//   const welcome = (
//     <div className="row">
//       <img src={img} width="100%" alt="" />
//     </div>
//   );

//   const year_bonum = (
//     <div className="row">
//       <img src={img} width="100%" alt="" />
//     </div>
//   );

//   const Monday_Bonus = (
//     <div className="row">
//       <img src={img} width="100%" alt="" />
//     </div>
//   );

//   const Refer_Win = (
//     <div className="row">
//       <img src={img} width="100%" alt="" />
//     </div>
//   );
//   //keyskills part start
//   const keyskills = (
//     <div className="row">
//       <img src={img} width="100%" alt="" />
//     </div>
//   );

//   const Refer_Earn = (
//     <div className="row">
//       <img src={img} width="100%" alt="" />
//     </div>
//   );

//   const Weekly_bonus = (
//     <div className="row">
//       <img src={img} width="100%" alt="" />
//     </div>
//   );

//   const genratebody1 = () => {
//     switch (obj[activeSectionKey]) {
//       case obj.Bonus:
//         return personInfo; // if key is "info" then return "personInfo object"
//       case obj.Lucky:
//         return workinfo; // if key is "WorkEx" then return "personInfo object"
//       case obj.Telegram:
//         return education; // if key is "edu" then return "personInfo object"
//       case obj.Recharge:
//         return keyskills; // if key is "KeySkills" then return "personInfo object"
//       case obj.Monday_Bonus:
//         return Monday_Bonus; // if key is "info" then return "personInfo object"
//       case obj.Refer_Win:
//         return Refer_Win; // if key is "WorkEx" then return "personInfo object"
//       case obj.Refer_Earn:
//         return Refer_Earn; // if key is "edu" then return "personInfo object"
//       case obj.year_bonum:
//         return year_bonum; // if key is "KeySkills" then return "personInfo object"
//       case obj.Weekly_bonus:
//         return Weekly_bonus; // if key is "edu" then return "personInfo object"
//       case obj.welcome:
//         return welcome; // if key is "KeySkills" then return "personInfo object"
//       default:
//         return null;
//     }
//   };
//   return (
//     <>
//       <Container
//         style={{
//           backgroundColor: "#4c1e14",
//           width: "100%",
//           marginTop: "0px",
//           // marginLeft: "0px",
//           // height: "90%",s
//           paddingLeft: "0px",
//           // marginRight: "0px",
//           paddingRight: "0px"
//         }}
//       >
//         <Grid container>
//           <Grid
//             item
//             xs={3}
//             style={{
//               height: "190px",
//               overflow: "auto"
//             }}
//           >
//             <List>
//               {Object.keys(obj)?.map((item) => (
//                 <ListItem
//                   style={{
//                     padding: "0px",
//                     marginTop: "1px",
//                     textAlign: "center",
//                     color: "#e35b04"
//                   }} // Set your desired font size, e.g., "16px"
//                   key={item}
//                   className={`${styless.section} ${
//                     activeSectionKey === item ? styless.activee : ""
//                   }`}
//                   onClick={() => setActiveSectionKey(item)}
//                 >
//                   <ListItemText className="item" primary={obj[item]} />
//                 </ListItem>
//               ))}
//             </List>
//           </Grid>
//           <Grid
//             item
//             xs={9}
//             style={{ fontSize: "11px", marginTop: "5px", color: "wheat" }}
//           >
//             {genratebody1()}
//           </Grid>
//         </Grid>
//       </Container>
//     </>
//   );
// };

// export default Popup;

// import { Container, Grid, List, ListItem, ListItemText } from "@mui/material";
// import React, { useState } from "react";
// import "./popups.css";
// import styless from "./Designer.module.css";
// import img from "../../image/icons/popup/background.jpg";
// // import bg_img from "../../image/icons/popup/7.png";
// import bg from "../../image/icons/refer earn/2.webp";
// import bg_img1 from "../../image/icons/popup/Screenshot 2024-01-06 at 11.05.20 AM.png";
// import { Close } from "@mui/icons-material";
// const Popup = ({ onClose }) => {
//   const obj = {
//     Bonus: "Deposite Bonus",
//     Lucky: "Lucky Player",
//     Telegram: "Telegram",
//     Recharge: "Recharge",
//     Monday_Bonus: "Monday Bonus",
//     Refer_Win: "Refer & Win"
//   };
//   const [activeSectionKey, setActiveSectionKey] = useState(Object.keys(obj)[0]);

//   // personal information part start
//   const personInfo = (
//     <div className="row" style={{ position: "relative", width: "100%" }}>
//       <img
//         src={bg_img1}
//         width="95%"
//         style={{
//           display: "block",
//           margin: "auto",
//           height: "180px",
//           width: "96%"
//         }}
//         alt=""
//       />
//     </div>
//   );
//   //personal information part end

//   //workinformation part start
//   const workinfo = (
//     <div className="row" style={{ position: "relative", width: "100%" }}>
//       <img
//         src={img}
//         width="95%"
//         style={{
//           display: "block",
//           margin: "auto",
//           height: "180px",
//           width: "96%"
//         }}
//         alt=""
//       />
//     </div>
//   );
//   //workinformation part end

//   //education part start

//   const education = (
//     <div className="row" style={{ position: "relative", width: "100%" }}>
//       <img
//         src={bg_img1}
//         width="95%"
//         style={{
//           display: "block",
//           margin: "auto",
//           height: "180px",
//           width: "96%"
//         }}
//         alt=""
//       />
//     </div>
//   );
//   //education part end

//   const welcome = (
//     <div className="row" style={{ position: "relative", width: "100%" }}>
//       <img
//         src={img}
//         width="95%"
//         style={{
//           display: "block",
//           margin: "auto",
//           height: "180px",
//           width: "96%"
//         }}
//         alt=""
//       />
//     </div>
//   );

//   const year_bonum = (
//     <div className="row" style={{ position: "relative", width: "100%" }}>
//       <img
//         src={bg_img1}
//         width="95%"
//         style={{
//           display: "block",
//           margin: "auto",
//           height: "180px",
//           width: "96%"
//         }}
//         alt=""
//       />
//     </div>
//   );

//   //   const Monday_Bonus = (
//   //     <div className="row" style={{position:"absolute",top:'65%',transform:'translateY(-50%)',width:'92%'}}>
//   //       <img src={img} width="90%" style={{display:'block',margin:'auto',height:'180px'}} alt="" />
//   //     </div>
//   //   );

//   //   const Refer_Win = (
//   //     <div className="row" style={{position:"absolute",top:'65%',transform:'translateY(-50%)',width:'92%'}}>
//   //       <img src={img} width="90%" style={{display:'block',margin:'auto',height:'180px'}} alt="" />
//   //     </div>
//   //   );
//   //   //keyskills part start

//   const genratebody1 = () => {
//     switch (obj[activeSectionKey]) {
//       case obj.Bonus:
//         return personInfo; // if key is "info" then return "personInfo object"
//       case obj.Lucky:
//         return workinfo; // if key is "WorkEx" then return "personInfo object"
//       case obj.Telegram:
//         return education; // if key is "edu" then return "personInfo object"
//       case obj.Recharge:
//         return welcome; // if key is "KeySkills" then return "personInfo object"
//       case obj.Monday_Bonus:
//         return year_bonum; // if key is "info" then return "personInfo object"
//       // if key is "WorkEx" then return "personInfo object"
//       // if key is "KeySkills" then return "personInfo object"
//       default:
//         return null;
//     }
//   };
//   return (
//     <>
//       <Container
//         style={{
//           backgroundImage: `url(${bg})`, // Specify the path to your image
//           backgroundSize: "99% 100%",
//           backgroundRepeat: "no-repeat",
//           backgroundPosition: "center",
//           width: "99%",
//           height: "100%",
//           paddingLeft: "0px",
//           paddingRight: "0px",
//           overflow: "auto",
//           paddingTop: "0px",
//           position: "relative",
//           textAlign: "center"
//         }}
//       >
//         <Grid container>
//           <Grid xs={12}>
//             <Close
//               onClick={onClose}
//               sx={{
//                 position: "absolute",
//                 right: "16px",
//                 top: "0px",
//                 fontSize: "35px",
//                 color: "white"
//               }}
//             />
//           </Grid>
//           <Grid
//             item
//             id="leftside"
//             xs={3}
//             sx={{
//               marginTop: "55px",
//               overflow: "auto",
//               height: "200px"
//               // fontSize: "15px"
//             }}
//           >
//             <List
//               sx={{
//                 paddingTop: "20px",
//                 paddingLeft: "10px"
//               }}
//             >
//               {Object.keys(obj)?.map((item) => (
//                 <ListItem
//                   style={{
//                     padding: "0px",
//                     marginTop: "1px",
//                     height: "35px",
//                     fontSize: "12px",
//                     textAlign: "center",
//                     color: "#e35b04"
//                   }} // Set your desired font size, e.g., "16px"
//                   key={item}
//                   className={`${styless.section} ${
//                     activeSectionKey === item ? styless.activee : ""
//                   }`}
//                   onClick={() => setActiveSectionKey(item)}
//                 >
//                   <ListItemText className="item" primary={obj[item]} />
//                 </ListItem>
//               ))}
//             </List>
//           </Grid>
//           <Grid
//             item
//             xs={9}
//             style={{
//               fontSize: "11px",
//               marginTop: "78px",
//               color: "wheat",
//               position: "relative",
//               display: "flex",
//               justifyContent: "center"
//             }}
//           >
//             {genratebody1()}
//           </Grid>
//         </Grid>
//       </Container>
//     </>
//   );
// };

// export default Popup;

// // import { Container, Grid, List, ListItem, ListItemText } from "@mui/material";
// // import React, { useState } from "react";
// // import "./popups.css";
// // import styless from "./Designer.module.css";
// // import img from "../../image/icons/background.jpg";
// // import bg_img from '../../image/icons/popup/popup/7.png'
// // import bg_img1 from '../../image/icons/popup/popup/Screenshot 2024-01-06 at 11.05.20 AM.png'
// // const Popup = () => {
// //   const obj = {
// //     Bonus: "Deposite Bonus",
// //     Lucky: "Lucky Player",
// //     Telegram: "Telegram",
// //     Recharge: "Recharge",
// //     Monday_Bonus: "Monday Bonus",
// //     Refer_Win: "Refer & Win",
// //     Refer_Earn: "Refer & Earn",
// //     year_bonum: "New Year Bonus",
// //     Weekly_bonus: "Weekly Bonus",
// //     welcome: "Welcome"
// //   };
// //   const [activeSectionKey, setActiveSectionKey] = useState(Object.keys(obj)[0]);

// //   // personal information part start
// //   const personInfo = (
// //     <div className="row" style={{position:"absolute",top:'65%',transform:'translateY(-50%)',width:'92%'}}>
// //       <img src={bg_img1} width="90%" style={{display:'block',margin:'auto',height:'180px'}} alt="" />
// //     </div>
// //   );
// //   //personal information part end

// //   //workinformation part start
// //   const workinfo = (
// //     <div className="row" style={{position:"absolute",top:'65%',transform:'translateY(-50%)',width:'92%'}}>
// //       <img src={img} width="90%" style={{display:'block',margin:'auto',height:'180px'}} alt="" />
// //     </div>
// //   );
// //   //workinformation part end

// //   //education part start

// //   const education = (
// //     <div className="row" style={{position:"absolute",top:'65%',transform:'translateY(-50%)',width:'92%'}}>
// //       <img src={bg_img1} width="90%" style={{display:'block',margin:'auto',height:'180px'}} alt="" />
// //     </div>
// //   );
// //   //education part end

// //   const welcome = (
// //     <div className="row" style={{position:"absolute",top:'65%',transform:'translateY(-50%)',width:'92%'}}>
// //       <img src={img} width="90%" style={{display:'block',margin:'auto',height:'180px'}} alt="" />
// //     </div>
// //   );

// //   const year_bonum = (
// //     <div className="row" style={{position:"absolute",top:'65%',transform:'translateY(-50%)',width:'92%'}}>
// //       <img src={bg_img1} width="90%" style={{display:'block',margin:'auto',height:'180px'}} alt="" />
// //     </div>
// //   );

// //   const Monday_Bonus = (
// //     <div className="row" style={{position:"absolute",top:'65%',transform:'translateY(-50%)',width:'92%'}}>
// //       <img src={img} width="90%" style={{display:'block',margin:'auto',height:'180px'}} alt="" />
// //     </div>
// //   );

// //   const Refer_Win = (
// //     <div className="row" style={{position:"absolute",top:'65%',transform:'translateY(-50%)',width:'92%'}}>
// //       <img src={img} width="90%" style={{display:'block',margin:'auto',height:'180px'}} alt="" />
// //     </div>
// //   );
// //   //keyskills part start
// //   const keyskills = (
// //     <div className="row" style={{position:"absolute",top:'65%',transform:'translateY(-50%)',width:'92%'}}>
// //       <img src={bg_img1} width="90%" style={{display:'block',margin:'auto',height:'180px'}} alt="" />
// //     </div>
// //   );

// //   const Refer_Earn = (
// //     <div className="row" style={{position:"absolute",top:'65%',transform:'translateY(-50%)',width:'92%'}}>
// //       <img src={img} width="90%" style={{display:'block',margin:'auto',height:'180px'}} alt="" />
// //     </div>
// //   );

// //   const Weekly_bonus = (
// //     <div className="row" style={{position:"absolute",top:'65%',transform:'translateY(-50%)',width:'92%'}}>
// //       <img src={bg_img1} width="90%" style={{display:'block',margin:'auto',height:'180px'}} alt="" />
// //     </div>
// //   );

// //   const genratebody1 = () => {
// //     switch (obj[activeSectionKey]) {
// //       case obj.Bonus:
// //         return personInfo; // if key is "info" then return "personInfo object"
// //       case obj.Lucky:
// //         return workinfo; // if key is "WorkEx" then return "personInfo object"
// //       case obj.Telegram:
// //         return education; // if key is "edu" then return "personInfo object"
// //       case obj.Recharge:
// //         return keyskills; // if key is "KeySkills" then return "personInfo object"
// //       case obj.Monday_Bonus:
// //         return Monday_Bonus; // if key is "info" then return "personInfo object"
// //       case obj.Refer_Win:
// //         return Refer_Win; // if key is "WorkEx" then return "personInfo object"
// //       case obj.Refer_Earn:
// //         return Refer_Earn; // if key is "edu" then return "personInfo object"
// //       case obj.year_bonum:
// //         return year_bonum; // if key is "KeySkills" then return "personInfo object"
// //       case obj.Weekly_bonus:
// //         return Weekly_bonus; // if key is "edu" then return "personInfo object"
// //       case obj.welcome:
// //         return welcome; // if key is "KeySkills" then return "personInfo object"
// //       default:
// //         return null;
// //     }
// //   };
// //   return (
// //     <>
// //       <Container
// //         style={{
// //           // backgroundColor: "#4c1e14",
// //           width: "100%",
// //           marginTop: "25px",
// //           // marginLeft: "0px",
// //           // height: "90%",s
// //           paddingLeft: "0px",
// //           // marginRight: "0px",
// //           paddingRight: "0px",
// //           backgroundImage:url(${bg_img}),
// //           backgroundSize:'cover',
// //           backgroundRepeat:'no-repeat'
// //         }}
// //       >
// //         <Grid container sx={{}}>
// //           <Grid item xs={3} sx={{marginTop:'55px'}}>
// //             <List sx={{paddingTop:'55px',paddingLeft:'10px'}} >
// //               {Object.keys(obj)?.map((item) => (
// //                 <ListItem
// //                   style={{
// //                     padding: "0px",
// //                     marginTop: "1px",
// //                     textAlign: "center",
// //                     color: "#e35b04"
// //                   }} // Set your desired font size, e.g., "16px"
// //                   key={item}
// //                   className={`${styless.section} ${
// //                     activeSectionKey === item ? styless.activee : ""
// //                   }`}
// //                   onClick={() => setActiveSectionKey(item)}
// //                 >
// //                   <ListItemText className="item" primary={obj[item]} />
// //                 </ListItem>
// //               ))}
// //             </List>
// //           </Grid>
// //           <Grid
// //             item
// //             xs={9}
// //             style={{ fontSize: "11px", marginTop: "5px", color: "wheat",position:'relative',display:'flex',alignItems:'center',justifyContent:'center' }}
// //           >
// //             {genratebody1()}
// //           </Grid>
// //         </Grid>
// //       </Container>
// //     </>
// //   );
// // };

// // export default Popup;

// // import { Container, Grid, List, ListItem, ListItemText } from "@mui/material";
// // import React, { useState } from "react";
// // import "./popups.css";
// // import styless from "./Designer.module.css";
// // import img from "../../image/icons/background.jpg";
// // const Popup = () => {
// //   const obj = {
// //     Bonus: "Deposite Bonus",
// //     Lucky: "Lucky Player",
// //     Telegram: "Telegram",
// //     Recharge: "Recharge",
// //     Monday_Bonus: "Monday Bonus",
// //     Refer_Win: "Refer & Win",
// //     Refer_Earn: "Refer & Earn",
// //     year_bonum: "New Year Bonus",
// //     Weekly_bonus: "Weekly Bonus",
// //     welcome: "Welcome"
// //   };
// //   const [activeSectionKey, setActiveSectionKey] = useState(Object.keys(obj)[0]);

// //   // personal information part start
// //   const personInfo = (
// //     <div className="row">
// //       <img src={img} width="100%" alt="" />
// //     </div>
// //   );
// //   //personal information part end

// //   //workinformation part start
// //   const workinfo = (
// //     <div className="row">
// //       <img src={img} width="100%" alt="" />
// //     </div>
// //   );
// //   //workinformation part end

// //   //education part start

// //   const education = (
// //     <div className="row">
// //       <img src={img} width="100%" alt="" />
// //     </div>
// //   );
// //   //education part end

// //   const welcome = (
// //     <div className="row">
// //       <img src={img} width="100%" alt="" />
// //     </div>
// //   );

// //   const year_bonum = (
// //     <div className="row">
// //       <img src={img} width="100%" alt="" />
// //     </div>
// //   );

// //   const Monday_Bonus = (
// //     <div className="row">
// //       <img src={img} width="100%" alt="" />
// //     </div>
// //   );

// //   const Refer_Win = (
// //     <div className="row">
// //       <img src={img} width="100%" alt="" />
// //     </div>
// //   );
// //   //keyskills part start
// //   const keyskills = (
// //     <div className="row">
// //       <img src={img} width="100%" alt="" />
// //     </div>
// //   );

// //   const Refer_Earn = (
// //     <div className="row">
// //       <img src={img} width="100%" alt="" />
// //     </div>
// //   );

// //   const Weekly_bonus = (
// //     <div className="row">
// //       <img src={img} width="100%" alt="" />
// //     </div>
// //   );

// //   const genratebody1 = () => {
// //     switch (obj[activeSectionKey]) {
// //       case obj.Bonus:
// //         return personInfo; // if key is "info" then return "personInfo object"
// //       case obj.Lucky:
// //         return workinfo; // if key is "WorkEx" then return "personInfo object"
// //       case obj.Telegram:
// //         return education; // if key is "edu" then return "personInfo object"
// //       case obj.Recharge:
// //         return keyskills; // if key is "KeySkills" then return "personInfo object"
// //       case obj.Monday_Bonus:
// //         return Monday_Bonus; // if key is "info" then return "personInfo object"
// //       case obj.Refer_Win:
// //         return Refer_Win; // if key is "WorkEx" then return "personInfo object"
// //       case obj.Refer_Earn:
// //         return Refer_Earn; // if key is "edu" then return "personInfo object"
// //       case obj.year_bonum:
// //         return year_bonum; // if key is "KeySkills" then return "personInfo object"
// //       case obj.Weekly_bonus:
// //         return Weekly_bonus; // if key is "edu" then return "personInfo object"
// //       case obj.welcome:
// //         return welcome; // if key is "KeySkills" then return "personInfo object"
// //       default:
// //         return null;
// //     }
// //   };
// //   return (
// //     <>
// //       <Container
// //         style={{
// //           backgroundColor: "#4c1e14",
// //           width: "100%",
// //           marginTop: "0px",
// //           // marginLeft: "0px",
// //           // height: "90%",s
// //           paddingLeft: "0px",
// //           // marginRight: "0px",
// //           paddingRight: "0px"
// //         }}
// //       >
// //         <Grid container>
// //           <Grid
// //             item
// //             xs={3}
// //             style={{
// //               height: "190px",
// //               overflow: "auto"
// //             }}
// //           >
// //             <List>
// //               {Object.keys(obj)?.map((item) => (
// //                 <ListItem
// //                   style={{
// //                     padding: "0px",
// //                     marginTop: "1px",
// //                     textAlign: "center",
// //                     color: "#e35b04"
// //                   }} // Set your desired font size, e.g., "16px"
// //                   key={item}
// //                   className={`${styless.section} ${
// //                     activeSectionKey === item ? styless.activee : ""
// //                   }`}
// //                   onClick={() => setActiveSectionKey(item)}
// //                 >
// //                   <ListItemText className="item" primary={obj[item]} />
// //                 </ListItem>
// //               ))}
// //             </List>
// //           </Grid>
// //           <Grid
// //             item
// //             xs={9}
// //             style={{ fontSize: "11px", marginTop: "5px", color: "wheat" }}
// //           >
// //             {genratebody1()}
// //           </Grid>
// //         </Grid>
// //       </Container>
// //     </>
// //   );
// // };

// // export default Popup;
