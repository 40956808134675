import React from "react";
// import img2 from "../../image/icons/graphics (1)/19.png";
// import img2 from "../../image/icons"
// import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// import Cookies from "js-cookie";
import loginIn from "../../image/icons/graphics (1)/logn.png";
// import { jwtDecode } from "jwt-decode";
import { Container, Grid } from "@mui/material";
// import login from "../../image/icons/RANK/RANK/13.webp";
// import Buy from "../Shop_Buy/Buy";
// import login from "../image/icons/RANK/RANK/13.webp";

const EditName = () => {
  const handlesubmit = () => {
    toast.error("In Process", {
      position: toast.POSITION.TOP_CENTER
    });
  };
  return (
    <>
      <Container>
        <Grid
          container
          style={{
            backgroundImage: `url(${loginIn})`,
            backgroundSize: "85% 100%",
            backgroundRepeat: "no-repeat",
            height: "210",
            paddingTop: "10px",
            marginTop: "30px",
            backgroundPosition: "center center"
          }}
        >
          <Grid xs={2}></Grid>
          <Grid xs={8}>
            <div className="main_form_start">
              <form
                action=""
                // onSubmit={submitdata}
                style={{ marginTop: "0px" }}
              >
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    marginTop: "25px",
                    color: "#ffc7bf",
                    marginBottom: "0px",
                    fontWeight: "bold"
                  }}
                >
                  Edit Name.
                </p>
                <p
                  style={{
                    textAlign: "left",
                    paddingLeft: "12px",
                    color: "#918f8d",
                    marginTop: "10px",
                    marginBottom: "0px"
                  }}
                >
                  <label
                    htmlFor=""
                    style={{ fontSize: "15px", color: "#f1e2e0" }}
                  >
                    Edit Name
                  </label>
                </p>
                <p
                  style={{
                    marginTop: "0px",
                    marginBottom: "0px",
                    paddingTop: "0px",
                    paddingBottum: "0px",
                    color: "#ffc7bf"
                  }}
                >
                  <input
                    style={{
                      width: "94%",
                      color: "#918f8d",
                      margin: "0% 2%",
                      padding: "5px 5px",
                      borderRadius: "5px",
                      backgroundColor: "#331115"
                    }}
                    type="number"
                    name="userphone"
                    // onChange={handleChangeform}
                    placeholder="Enter Number"
                    // value={formdata.userphone}
                    id=""
                  />
                </p>

                <p onClick={handlesubmit}>
                  <button
                    style={{
                      backgroundColor: "green",
                      color: "azure",
                      width: "98%",
                      justifyContent: "center",
                      margin: "0% 1%",
                      borderRadius: "6px",
                      padding: "7px 5px"
                    }}
                  >
                    Edit Name
                  </button>
                </p>
              </form>
            </div>
          </Grid>
          <Grid xs={2}></Grid>
        </Grid>
        <ToastContainer />
      </Container>
      <ToastContainer />
    </>
  );
};

export default EditName;
