import axios from "axios";

// Action creator function to fetch data from Coingecko API
export const fetchData = (id) => {
  return async (dispatch) => {
    try {
      // Check if id is defined before making the API call
      if (!id) {
        console.error("User ID is undefined");
        return;
      }

      // Send a GET request to the API endpoint
      const response = await axios.get(
        `https://ruby-important-panther.cyclic.app/Getalldatauser/${id}`
      );
      // console.log(response, "bycrypt");
      dispatch({
        type: "FETCH_API1_DATA_SUCCESS",
        payload: response.data
      });
      // console.log(response, "response");
    } catch (error) {
      // Dispatch a failure action if there is an error
      dispatch({
        type: "FETCH_API1_DATA_FAILURE",
        payload: error.message
      });
    }
  };
};

export const UpiDeatilsOfUserget = (id) => {
  // console.log(id, "id");
  return async (dispatch) => {
    try {
      // Send a GET request to the Coingecko API
      const response = await axios.get(
        `https://ruby-important-panther.cyclic.app/UpiDeatilsOfUserget/${id}`
      );

      console.log(response.data, "UpiDeatilsOfUserget");
      // Dispatch a success action with the fetched data
      dispatch({
        type: "FETCH_UPIDETAILS_API1_DATA_SUCCESS",
        payload: response.data
      });
    } catch (error) {
      // Dispatch a failure action if there is an error
      dispatch({
        type: "FETCH_UPIDETAILS_DATA_FAILURE",
        payload: error.message
      });
    }
  };
};

export const BankDeatilsOfUserget = (id) => {
  // console.log(id, "id");
  return async (dispatch) => {
    try {
      // Send a GET request to the Coingecko API
      const response = await axios.get(
        `https://ruby-important-panther.cyclic.app/BankDeatilsOfUserGet/${id}`
      );

      // console.log(response.data, "UpiDeatilsOfUserget");
      // console.log(response.data, "Getalldatauser");
      // Dispatch a success action with the fetched data
      dispatch({
        type: "FETCH_BANKDETAILS_API1_DATA_SUCCESS",
        payload: response.data
      });
    } catch (error) {
      // Dispatch a failure action if there is an error
      dispatch({
        type: "FETCH_BANKDETAILS_DATA_FAILURE",
        payload: error.message
      });
    }
  };
};
// Action types
// export const clearApi1Data = () => ({
//   type: "CLEAR_API1_DATA"
// });

// export const clearApi2Data = () => ({
//   type: "CLEAR_API2_DATA"
// });

// export const fetchGameData = () => {
//   return async (dispatch) => {
//     try {
//       // Send a GET request to the Coingecko API
//       const response = await axios.get("https://ruby-important-panther.cyclic.app/GamePriceGet");
//       // console.log(response.data, "Getalldatauser");
//       // Dispatch a success action with the fetched data
//       dispatch({
//         type: "FETCH_GAME_API1_DATA_SUCCESS",
//         payload: response.data
//       });
//     } catch (error) {
//       // Dispatch a failure action if there is an error
//       dispatch({
//         type: "FETCH_GAME_API1_DATA_FAILURE",
//         payload: error.message
//       });
//     }
//   };
// };

export const getcarromControllerPrice = ({ game }) => {
  // console.log("looio", game);
  return async (dispatch) => {
    try {
      // Send a GET request to the Coingecko API
      const response = await axios.get(
        `https://ruby-important-panther.cyclic.app/GamePriceGet/${game}`
      );
      // console.log(response.data, "Getalldatauser");
      // Dispatch a success action with the fetched data
      dispatch({
        type: "FETCH_GAMELOBBY_API1_DATA_SUCCESS",
        payload: response.data
      });
    } catch (error) {
      // Dispatch a failure action if there is an error
      dispatch({
        type: "FETCH_GAMELOBBY_API1_DATA_FAILURE",
        payload: error.message
      });
    }
  };
};

// export const fetcballpoolData = () => {
//   return async (dispatch) => {
//     try {
//       // Send a GET request to the Coingecko API
//       const response = await axios.get(
//         "https://ruby-important-panther.cyclic.app/getboolControllerPrice"
//       );
//       // console.log(response.data, "Getalldatauser");
//       // Dispatch a success action with the fetched data
//       dispatch({
//         type: "FETCH_POOL_API1_DATA_SUCCESS",
//         payload: response.data
//       });
//     } catch (error) {
//       // Dispatch a failure action if there is an error
//       dispatch({
//         type: "FETCH_POOL_API1_DATA_FAILURE",
//         payload: error.message
//       });
//     }
//   };
// };

// export const fetchDataofpoolhistory = () => {
//   return async (dispatch) => {
//     try {
//       // Send a GET request to the Coingecko API
//       const response = await axios.get(
//         "https://ruby-important-panther.cyclic.app/getpoopdatahistory"
//       );
//       console.log(response.data, "Getalldatauser");
//       // Dispatch a success action with the fetched data
//       dispatch({
//         type: "FETCH_POOL_HISTORY_API1_DATA_SUCCESS",
//         payload: response.data
//       });
//     } catch (error) {
//       // Dispatch a failure action if there is an error
//       dispatch({
//         type: "FETCH_POOL_HISTORY_API1_DATA_FAILURE",
//         payload: error.message
//       });
//     }
//   };
// };

// export const fetchDataofludohistory = () => {
//   return async (dispatch) => {
//     try {
//       // Send a GET request to the Coingecko API
//       const response = await axios.get(
//         "https://ruby-important-panther.cyclic.app/getludodatahistory"
//       );
//       console.log(response.data, "getludodatahistory");
//       // Dispatch a success action with the fetched data
//       dispatch({
//         type: "FETCH_LUDO_HISTORY_API1_DATA_SUCCESS",
//         payload: response.data
//       });
//     } catch (error) {
//       // Dispatch a failure action if there is an error
//       dispatch({
//         type: "FETCH_LUDO_HISTORY_API1_DATA_FAILURE",
//         payload: error.message
//       });
//     }
//   };
// };

// export const fetchDataofcarromhistory = () => {
//   return async (dispatch) => {
//     try {
//       // Send a GET request to the Coingecko API
//       const response = await axios.get(
//         "https://ruby-important-panther.cyclic.app/getcarromdatahistory"
//       );
//       console.log(response.data, "Getalldatauser");
//       // Dispatch a success action with the fetched data
//       dispatch({
//         type: "FETCH_CARROM_HISTORY_API1_DATA_SUCCESS",
//         payload: response.data
//       });
//     } catch (error) {
//       // Dispatch a failure action if there is an error
//       dispatch({
//         type: "FETCH_CARROM_HISTORY_API1_DATA_FAILURE",
//         payload: error.message
//       });
//     }
//   };
// };
