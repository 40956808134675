// Initial state of the data
const initialState = {
  api1Data: [], // Data from API 1
  api2Data: [], // Data from API 2
  api3Data: [], // Data from API 3
  api4Data: [], // Data from API 4
  //   api5Data: [],
  //   api6Data: [],
  //   api7Data: [],
  error: null // Error message
};

// Data reducer function
export const dataReducer = (state = initialState, action) => {
  // console.log("Reducer action type:", action.type);
  // console.log("Reducer state:", state);
  switch (action.type) {
    case "FETCH_API1_DATA_SUCCESS":
      return {
        ...state,
        api1Data: action.payload, // Update API 1 data
        error: null // Clear error message
      };
    case "FETCH_API1_DATA_FAILURE":
      return {
        ...state,
        api1Data: [], // Clear API 1 data
        error: action.payload // Update error message
      };
    case "FETCH_UPIDETAILS_API1_DATA_SUCCESS":
      return {
        ...state,
        api2Data: action.payload,
        error: null
      };
    case "FETCH_UPIDETAILS_DATA_FAILURE":
      return {
        ...state,
        api2Data: [] // Clear API 1 data
      };
    case "FETCH_BANKDETAILS_API1_DATA_SUCCESS":
      return {
        ...state,
        api3Data: action.payload,
        error: null
      };
    case "FETCH_BANKDETAILS_DATA_FAILURE":
      return {
        ...state,
        api3Data: [] // Clear API 1 data
      };

    case "FETCH_GAMELOBBY_API1_DATA_SUCCESS":
      return {
        ...state,
        api4Data: action.payload,
        error: null
      };
    case "FETCH_GAMELOBBY_API1_DATA_FAILURE":
      return {
        ...state,
        api4Data: [], // Clear API 1 data
        error: null // Update error message
      };
    // case "FETCH_POOL_API1_DATA_SUCCESS":
    //   return {
    //     ...state,
    //     api4Data: action.payload,
    //     error: null
    //   };
    // case "FETCH_POOL_API1_DATA_FAILURE":
    //   return {
    //     ...state,
    //     api4Data: [], // Clear API 1 data
    //     error: null // Update error message
    //   };

    // case "FETCH_POOL_HISTORY_API1_DATA_SUCCESS":
    //   return {
    //     ...state,
    //     api5Data: action.payload,
    //     error: null
    //   };
    // case "FETCH_POOL_HISTORY_API1_DATA_FAILURE":
    //   return {
    //     ...state,
    //     api5Data: [], // Clear API 1 data
    //     error: null // Update error message
    //   };
    // case "FETCH_LUDO_HISTORY_API1_DATA_SUCCESS":
    //   return {
    //     ...state,
    //     api6Data: action.payload,
    //     error: null
    //   };
    // case "FETCH_LUDO_HISTORY_API1_DATA_FAILURE":
    //   return {
    //     ...state,
    //     api6Data: [], // Clear API 1 data
    //     error: null // Update error message
    //   };
    // case "FETCH_CARROM_HISTORY_API1_DATA_SUCCESS":
    //   return {
    //     ...state,
    //     api7Data: action.payload,
    //     error: null
    //   };
    // case "FETCH_CARROM_HISTORY_API1_DATA_FAILURE":
    //   return {
    //     ...state,
    //     api7Data: [], // Clear API 1 data
    //     error: null // Update error message
    //   };

    default:
      return state; // Return current state if action type is not recognized
  }
};
