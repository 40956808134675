import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText
  // Typography
} from "@mui/material";
import styless from "./Designer.module.css";
// import img className="mail-Img" from "../../image/icons/background.jpg";
import "../Mail/Mail.css";
// import bg from "../../image/icons/popup/popup/bg.png";
import React, { useState } from "react";
import header from "../../image/icons/graphics (1)/header.png";
// import header from "../../image/icons/profile/rtmap2.png";
import mail from "../../image/icons/graphics (1)/mail.png";
// import coin from "../../image/icons/graphics (1)/20.png";
import leftside from "../../image/icons/profile/9.webp";
import "../../styles/Home.css";
import rightside from "../../image/icons/profile/10.webp";
import plate2 from "../../image/icons/User_Profile/5.png";
import { Close } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import music from "../../image/icons/popup/music.png";
import sound from "../../image/icons/popup/n1.png";
import logout from "../../image/icons/popup/logout.png";
import { SyncLoader } from "react-spinners";

const Setting = ({ onClose }) => {
  const a = [mail, leftside, rightside, music, sound, logout];
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const loadImage = (url) => {
      return new Promise((resolve) => {
        const image = new Image();
        image.src = url;

        image.onload = () => {
          resolve();
        };
        if (image.complete) {
          resolve();
        }
      });
    };

    const loadImages = async () => {
      // Load regular images
      const regularImageLoadPromises = a.map((img) => loadImage(img));

      try {
        await Promise.all(regularImageLoadPromises).then(() => {
          setLoaded(true);
        });
      } catch (error) {
        console.error("Image loading error:", error);
      }
    };

    loadImages();
  }, []);

  const obj = {
    Bonus: "Deposite Bonus",
    Lucky: "Lucky Player",
    Telegram: "Telegram"
  };
  const [activeSectionKey, setActiveSectionKey] = useState(Object.keys(obj)[0]);

  // personal information part start
  const personInfo = (
    <div className="row">
      <Box
        style={{
          backgroundColor: "#591b1beb",
          margin: "2px 10px ",
          borderRadius: "5px",
          color: "#f37429",
          display: "flex",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "5px"
        }}
      >
        <div style={{ width: "90%" }}>
          <div
            style={{
              backgroundImage: `url(${plate2})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              width: "90%",
              margin: "20px auto",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              padding: "27px"
            }}
          >
            {/* <img className="mail-Img" src={plate2} style={{ width: "90%",display:'block',margin:'auto',textAlign:'center' }} alt="" /> */}
            <img src={music} style={{ width: "30px" }} alt="" />
            <img src={sound} style={{ width: "30px" }} alt="" />
          </div>
          <div style={{ margin: "30px" }}>
            <img
              src={logout}
              style={{
                display: "block",
                margin: "auto",
                textAlign: "center",
                width: "120px"
              }}
              alt=""
            />
          </div>
        </div>
      </Box>
    </div>
  );
  //personal information part end

  //workinformation part start
  const workinfo = (
    <div className="row">
      <Box
        style={{
          backgroundColor: "pink",
          margin: "2px 10px ",
          borderRadius: "5px",
          color: "black"
        }}
      >
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab unde,
      </Box>
      <Box
        style={{
          backgroundColor: "pink",
          margin: "2px 10px ",
          borderRadius: "5px",
          color: "black"
        }}
      >
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab unde,
      </Box>
      <Box
        style={{
          backgroundColor: "pink",
          margin: "2px 10px ",
          borderRadius: "5px",
          color: "black"
        }}
      >
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab unde,
      </Box>
      <Box
        style={{
          backgroundColor: "pink",
          margin: "2px 10px ",
          borderRadius: "5px",
          color: "black"
        }}
      >
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab unde,
      </Box>
      <Box
        style={{
          backgroundColor: "pink",
          margin: "2px 10px ",
          borderRadius: "5px",
          color: "black"
        }}
      >
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab unde,
      </Box>
      <Box
        style={{
          backgroundColor: "pink",
          margin: "2px 10px ",
          borderRadius: "5px",
          color: "black"
        }}
      >
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab unde,
      </Box>
      <Box
        style={{
          backgroundColor: "pink",
          margin: "2px 10px ",
          borderRadius: "5px",
          color: "black"
        }}
      >
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab unde,
      </Box>
      <Box
        style={{
          backgroundColor: "pink",
          margin: "2px 10px ",
          borderRadius: "5px",
          color: "black"
        }}
      >
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab unde,
      </Box>
    </div>
  );
  //workinformation part end

  const education = (
    <div className="row">
      <Box
        style={{
          backgroundColor: "pink",
          margin: "2px 10px ",
          borderRadius: "5px",
          color: "black"
        }}
      >
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab unde,
      </Box>
      <Box
        style={{
          backgroundColor: "pink",
          margin: "2px 10px ",
          borderRadius: "5px",
          color: "black"
        }}
      >
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab unde,
      </Box>
      <Box
        style={{
          backgroundColor: "pink",
          margin: "2px 10px ",
          borderRadius: "5px",
          color: "black"
        }}
      >
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab unde,
      </Box>
      <Box
        style={{
          backgroundColor: "pink",
          margin: "2px 10px ",
          borderRadius: "5px",
          color: "black"
        }}
      >
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab unde,
      </Box>
      <Box
        style={{
          backgroundColor: "pink",
          margin: "2px 10px ",
          borderRadius: "5px",
          color: "black"
        }}
      >
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab unde,
      </Box>
      <Box
        style={{
          backgroundColor: "pink",
          margin: "2px 10px ",
          borderRadius: "5px",
          color: "black"
        }}
      >
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab unde,
      </Box>
      <Box
        style={{
          backgroundColor: "pink",
          margin: "2px 10px ",
          borderRadius: "5px",
          color: "black"
        }}
      >
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab unde,
      </Box>
      <Box
        style={{
          backgroundColor: "pink",
          margin: "2px 10px ",
          borderRadius: "5px",
          color: "black"
        }}
      >
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab unde,
      </Box>
    </div>
  );
  //education part end

  const genratebody1 = () => {
    switch (obj[activeSectionKey]) {
      case obj.Bonus:
        return personInfo; // if key is "info" then return "personInfo object"
      case obj.Lucky:
        return workinfo; // if key is "WorkEx" then return "personInfo object"
      case obj.Telegram:
        return education; // if key is "edu" then return "personInfo object"
      default:
        return null;
    }
  };
  return (
    <>
      {loaded ? (
        <Container
          id="verticalbar"
          style={{
            height: "100%",
            width: "100%",
            marginTop: "0",
            WebkitScrollSnapType: "none",
            padding: "0px 10px",
            position: "relative",
            top: "50%",
            transform: "translateY(-50%)"
          }}
        >
          <Grid
            container
            id="vertical"
            style={{
              backgroundColor: "#893a2e",
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px"
            }}
          >
            <Grid item xs={12} sx={{ marginBottom: "0px" }}>
              <div style={{ position: "relative" }}>
                <img
                  className="mail-Img"
                  src={header}
                  style={{ width: "100%", height: "40px" }}
                  alt=""
                />
              </div>
              <Link>
                <Close
                  onClick={onClose}
                  sx={{
                    position: "absolute",
                    right: "16px",
                    top: "0px",
                    fontSize: "35px",
                    color: "white"
                  }}
                />
              </Link>
              <div
                style={{
                  position: "absolute",
                  top: "2px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <img
                  className="mail-Img"
                  src={mail}
                  style={{ width: "60px" }}
                  alt=""
                />
                <span style={{ fontSize: "25px", color: "white" }}>Mail</span>
              </div>
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                backgroundImage: `url(${leftside})`, // Specify the path to your image
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "95%",
                height: "215px",
                paddingLeft: "0px",
                paddingRight: "0px",
                overflow: "auto",
                paddingTop: "0px",
                position: "relative",
                textAlign: "center"
              }}
            >
              <List>
                {Object.keys(obj)?.map((item) => (
                  <ListItem
                    style={{
                      padding: "0px",
                      textAlign: "center",
                      color: "#e35b04",
                      height: "58px"
                    }} // Set your desired font size, e.g., "16px"
                    key={item}
                    className={`${styless.section} ${
                      activeSectionKey === item ? styless.activee : ""
                    }`}
                    onClick={() => setActiveSectionKey(item)}
                  >
                    <ListItemText className="item" primary={obj[item]} />
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid
              item
              xs={9}
              style={{
                backgroundImage: `url(${rightside})`, // Specify the path to your image
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "95%",
                height: "215px",
                paddingLeft: "0px",
                paddingRight: "0px",
                color: "wheat",
                overflow: "auto",
                paddingTop: "0px",
                position: "relative",
                fontSize: "11px",
                textAlign: "center"
              }}
            >
              {genratebody1()}
            </Grid>
          </Grid>
        </Container>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Adjust the height as needed
            textAlign: "center",
            color: "#bd5c0c"
          }}
        >
          <SyncLoader size={12} color={"#bd5c0c"} />
        </div>
      )}
    </>
  );
};

export default Setting;
