import React from "react";
import { Container } from "@mui/material";
// import bg_img from "../../image/icons/graphics (1)/pop bg.png";
import bg_img from "../../image/icons/referwin/referwin/4.webp";
import { Link } from "react-router-dom";
import Close from "@mui/icons-material/Close";

const Innerpopup = ({ onClose }) => {
  return (
    <Container
      style={{
        width: "80%",
        height: "80%"
      }}
    >
      <div style={{ position: "relative" }}>
        <img src={bg_img} width={"100%"} style={{ height: "220px" }} alt="" />
        <div style={{ textAlign: "center" }}>
          <p
            style={{
              position: "absolute",
              top: "20px",
              padding: "0 50px",
              color: "white"
            }}
          >
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Optio
            numquam error laboriosam maiores est dicta ratione, natus quos
            tenetur, iure ab tempora officia temporibus quae labore nihil.
            Voluptates, dicta odio.
          </p>
        </div>
        <Link>
          <Close
            onClick={onClose}
            sx={{
              position: "absolute",
              top: "7px",
              right: "7px",
              fontSize: "35px",
              color: "white"
            }}
          />
        </Link>
      </div>
    </Container>
  );
};

export default Innerpopup;
