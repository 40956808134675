import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { Container } from "@mui/material";
import bg from "../../image/icons/bg_referearn.png";
import { useState } from "react";
import { List } from "@mui/material";
import { ListItem } from "@mui/material";
import { ListItemText } from "@mui/material";
import styless from "./Designer.module.css";
import header from "../../image/icons/6.png";
// import img from "../../image/icons/popup/popup/Screenshot 2024-01-06 at 11.05.20 AM.png";
import fb from "../../image/icons/fb.png";
import whatsapp from "../../image/icons/watsapp.png";
import share from "../../image/icons/copy.png";
import coin from "../../image/icons/4.png";
import box from "../../image/icons/popup-bg.jpg";
import { SyncLoader } from "react-spinners";
import { Close } from "@mui/icons-material";

const ReferWin = ({ onClose }) => {
  const a = [fb, box, whatsapp, share, coin, bg, header];
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const loadImage = (url) => {
      return new Promise((resolve) => {
        const image = new Image();
        image.src = url;

        image.onload = () => {
          resolve();
        };
        if (image.complete) {
          resolve();
        }
      });
    };

    const loadImages = async () => {
      // Load regular images
      const regularImageLoadPromises = a.map((img) => loadImage(img));

      try {
        await Promise.all(regularImageLoadPromises).then(() => {
          setLoaded(true);
        });
      } catch (error) {
        console.error("Image loading error:", error);
      }
    };

    loadImages();
  }, []);
  const obj = {
    Bonus: "Deposite Bonus",
    Lucky: "Lucky Player",
    Telegram: "Telegram",
    Monday_Bonus: "Monday Bonus",
    year_bonum: "Weekly Bonus",
    welcome: "Welcome"
  };

  const [activeSectionKey, setActiveSectionKey] = useState(Object.keys(obj)[0]);

  const personInfo = (
    <div className="row" style={{}}>
      <img src={header} width={"50%"} alt="" />
      <p
        style={{
          margin: "0 auto",
          color: "yellow",
          fontSize: "9px",
          textAlign: "center"
        }}
      >
        Share link to earn
      </p>
      <img
        src={box}
        alt=""
        style={{
          height: "130px",
          display: "block",
          margin: "auto",
          width: "50%"
        }}
      />
    </div>
  );
  //personal information part end

  //workinformation part start
  const workinfo = (
    <div
      className="row"
      style={{
        position: "absolute",
        top: "65%",
        transform: "translateY(-50%)",
        width: "92%"
      }}
    >
      {/* <img src={img} width="90%" style={{display:'block',margin:'auto',height:'180px'}} alt="" />  */}
    </div>
  );
  //workinformation part end

  //education part start

  const education = (
    <div
      className="row"
      style={{
        position: "absolute",
        top: "65%",
        transform: "translateY(-50%)",
        width: "92%"
      }}
    >
      {/* <img src={bg_img1} width="90%" style={{display:'block',margin:'auto',height:'180px'}} alt="" />  */}
    </div>
  );
  //education part end

  const welcome = (
    <div
      className="row"
      style={{
        position: "absolute",
        top: "65%",
        transform: "translateY(-50%)",
        width: "92%"
      }}
    >
      {/* <img src={img} width="90%" style={{display:'block',margin:'auto',height:'180px'}} alt="" />  */}
    </div>
  );

  const year_bonum = (
    <div
      className="row"
      style={{
        position: "absolute",
        top: "65%",
        transform: "translateY(-50%)",
        width: "92%"
      }}
    >
      {/* <img src={bg_img1} width="90%" style={{display:'block',margin:'auto',height:'180px'}} alt="" />  */}
    </div>
  );

  const Monday_Bonus = (
    <div
      className="row"
      style={{
        position: "absolute",
        top: "65%",
        transform: "translateY(-50%)",
        width: "92%"
      }}
    >
      {/* <img src={img} width="90%" style={{display:'block',margin:'auto',height:'180px'}} alt="" />  */}
    </div>
  );

  const genratebody1 = () => {
    switch (obj[activeSectionKey]) {
      case obj.Bonus:
        return personInfo;
      case obj.Lucky:
        return workinfo;
      case obj.Telegram:
        return education;
      case obj.Monday_Bonus:
        return Monday_Bonus;
      case obj.year_bonum:
        return year_bonum;
      case obj.welcome:
        return welcome;
      default:
        return null;
    }
  };

  return (
    <>
      {loaded ? (
        <Container
          style={{
            backgroundImage: `url(${bg})`, // Specify the path to your image
            backgroundSize: "85%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "95%",
            height: "95%",
            paddingLeft: "0px",
            paddingRight: "0px",
            overflow: "auto",
            paddingTop: "0px",
            position: "relative",
            textAlign: "center"
          }}
        >
          <Close
            onClick={onClose}
            sx={{
              position: "absolute",
              right: "16px",
              top: "10px",
              fontSize: "25px",
              color: "white"
            }}
          />
          <Grid
            container
            sx={{ position: "absolute", top: "45px", width: "100%" }}
          >
            {/* <Grid item xs={3} sx={{ fontSize: "25px" }}>
              <div style={{ textAlign: "center" }}>
                <img src={coin} alt="" style={{ width: "41px" }} />
                <img
                  src={coin}
                  alt=""
                  style={{ width: "58px", marginLeft: "-14px" }}
                />
              </div>
              <List sx={{}}>
                {Object.keys(obj)?.map((item) => (
                  <ListItem
                    style={{
                      padding: "0px",
                      // marginTop: "10px",
                      textAlign: "center",
                      color: "#ff9e73",
                      fontSize: "18px",
                      height: "30px"
                    }} // Set your desired font size, e.g., "16px"
                    key={item}
                    className={`${styless.section} ${
                      activeSectionKey === item ? styless.activee : ""
                    }`}
                    onClick={() => setActiveSectionKey(item)}
                  >
                    <ListItemText
                      style={{ fontSize: "17px" }}
                      className="item"
                      primary={obj[item]}
                    />
                  </ListItem>
                ))}
              </List>
            </Grid> */}

            <Grid item xs={12} sx={{}}>
              {genratebody1()}
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                // display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <img src={fb} alt="" width={"80px"} />
              <img src={whatsapp} alt="" width={"80px"} />
              <img src={share} alt="" width={"80px"} />
            </Grid>
          </Grid>
        </Container>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Adjust the height as needed
            textAlign: "center",
            color: "#bd5c0c"
          }}
        >
          <SyncLoader size={12} color={"#bd5c0c"} />
        </div>
      )}
    </>
  );
};

export default ReferWin;
