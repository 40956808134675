import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import bg from "../../image/icons/referwin/referwin/aa.webp";
// import bg from "../../image/icons/logout.png";
import fb from "../../image/icons/referwin/referwin/2.webp";
import whatsapp from "../../image/icons/referwin/referwin/1.webp";
import copy from "../../image/icons/referwin/referwin/3.webp";
import { Link } from "react-router-dom";
import Close from "@mui/icons-material/Close";
import { SyncLoader } from "react-spinners";

function Refer({ onClose }) {
  const a = [fb, copy, whatsapp, bg];
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const loadImage = (url) => {
      return new Promise((resolve) => {
        const image = new Image();
        image.src = url;

        image.onload = () => {
          resolve();
        };
        if (image.complete) {
          resolve();
        }
      });
    };

    const loadImages = async () => {
      // Load regular images
      const regularImageLoadPromises = a.map((img) => loadImage(img));

      try {
        await Promise.all(regularImageLoadPromises).then(() => {
          setLoaded(true);
        });
      } catch (error) {
        console.error("Image loading error:", error);
      }
    };

    loadImages();
  }, []);
  return (
    <>
      {loaded ? (
        <Container
          style={{
            backgroundImage: `url(${bg})`, // Specify the path to your image
            backgroundSize: "95% 95%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "95%",
            height: "100%",
            paddingLeft: "0px",
            paddingRight: "0px",
            overflow: "auto",
            paddingTop: "0px",
            position: "relative",
            textAlign: "center"
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              position: "absolute",
              bottom: "22px",
              width: "100%"
            }}
          >
            <img src={whatsapp} alt="" width={"80px"} />
            <img src={fb} alt="" width={"80px"} />
            <img src={copy} alt="" width={"80px"} />
          </div>
          <Link>
            <Close
              onClick={onClose}
              style={{
                position: "absolute",
                top: "10px",
                right: "30px",
                color: "white",
                fontSize: "35px"
              }}
            />
          </Link>
        </Container>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Adjust the height as needed
            textAlign: "center",
            color: "#bd5c0c"
          }}
        >
          <SyncLoader size={12} color={"#bd5c0c"} />
        </div>
      )}
    </>
  );
}

export default Refer;
