import { Container, Grid, List, ListItem, ListItemText } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./buy.css";
import styless from "./Designer.module.css";
import img from "../../image/icons/background.jpg";
import img1 from "../../image/icons/popup/popup/coin.png";
import img2 from "../../image/icons/popup/popup/coin1.jpeg";
import BonusImg1 from "../../image/icons/popup/popup/POP.png";
import ButtonImg from "../../image/icons/popup/popup/button.png";
import { Close } from "@mui/icons-material";
import { SyncLoader } from "react-spinners";

const Buy = ({ onClose }) => {
  const [loaded, setLoaded] = useState(false);
  const a = [img, img1, img2, BonusImg1, ButtonImg];

  useEffect(() => {
    const loadImage = (url) => {
      return new Promise((resolve) => {
        const image = new Image();
        image.src = url;

        image.onload = () => {
          resolve();
        };
        if (image.complete) {
          resolve();
        }
      });
    };

    const loadImages = async () => {
      // Load regular images
      const regularImageLoadPromises = a.map((img) => loadImage(img));

      try {
        await Promise.all(regularImageLoadPromises).then(() => {
          setLoaded(true);
        });
      } catch (error) {
        console.error("Image loading error:", error);
      }
    };

    loadImages();
  }, []);

  const obj = {
    Bonus: "UPI1",
    Lucky: "UPI2",
    Telegram: "UPI3",
    Recharge: "UPI4",
    Monday_Bonus: "UPI5",
    Refer_Win: "UPI6",
    Refer_Earn: "UPI7"
  };
  const imgData = [
    {
      price: 100,
      bonus: 0
    },
    {
      price: 200,
      bonus: 0
    },
    {
      price: 300,
      bonus: 0
    },
    {
      BonusImg: BonusImg1,
      bonus: 2,
      price: 500
    },
    {
      BonusImg: BonusImg1,
      bonus: 2,
      price: 1000
    },
    {
      BonusImg: BonusImg1,
      bonus: 2,
      price: 2000
    },
    {
      bonusImg: BonusImg1,
      bonus: 0,
      price: 3000
    },
    {
      BonusImg: BonusImg1,
      bonus: 2,
      price: 5000
    },
    {
      BonusImg: BonusImg1,
      bonus: 2,
      price: 8000
    },
    {
      BonusImg: BonusImg1,
      bonus: 2,
      price: 10000
    },
    {
      BonusImg: BonusImg1,
      bonus: 2,
      price: 30000
    },
    {
      BonusImg: BonusImg1,
      bonus: 2,
      price: 50000
    }
  ];
  const [activeSectionKey, setActiveSectionKey] = useState(Object.keys(obj)[0]);

  // personal information part start
  const [selectedImg, setSelectedImg] = useState({});
  const handleImgClick = (index, bonus, price) => {
    setSelectedImg({
      ...selectedImg,
      index: index,
      bonus: bonus,
      price: price
    });
  };
  const personInfo = (
    <div style={{ position: "relative" }} className="row">
      <img src={img} width="100%" height="200px" alt="" />
      <div
        style={{
          position: "absolute",
          top: "0",
          height: "175px",
          overflow: "scroll",
          margin: "auto",
          textAlign: "center"
        }}
      >
        {imgData.map((img, index) => (
          <div
            style={{ display: "inline-block", position: "relative" }}
            onClick={() => handleImgClick(index, img.bonus, img.price)}
          >
            <img
              src={index === selectedImg.index ? img2 : img1}
              alt=""
              style={{ width: "110px" }}
            />
            <div style={{ position: "absolute", top: "-15px", right: "-15px" }}>
              <img src={img.BonusImg} alt="" style={{ width: "60px" }} />
            </div>
            <div
              style={{
                position: "absolute",
                top: "60%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                fontSize: "16px"
              }}
            >
              {/* Your content goes here */}

              <p>{img.price}</p>
            </div>
          </div>
        ))}
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "0px",
          height: "35px",
          backgroundColor: "#710606",
          zIndex: "100",
          width: "100%"
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "35px",
            justifyContent: "space-evenly"
          }}
        >
          <div>
            <p style={{ marginBottom: "-12px" }}>
              <span
                style={{
                  display: "inline-block",
                  width: "80px",
                  textAlign: "center",
                  marginLeft: "10px",
                  height: "10px"
                }}
              >
                Principle
              </span>
              <span
                style={{
                  display: "inline-block",
                  width: "40px",
                  textAlign: "center"
                }}
              ></span>
              <span
                style={{
                  display: "inline-block",
                  width: "80px",
                  textAlign: "center"
                }}
              >
                Bonus
              </span>
            </p>
            <p
              style={{
                backgroundColor: "black",
                height: "20px",
                borderRadius: "10px",
                fontSize: "14px",
                marginLeft: "10px"
              }}
            >
              <span
                style={{
                  display: "inline-block",
                  width: "80px",
                  textAlign: "center"
                }}
              >
                {selectedImg.price}
              </span>
              <span
                style={{
                  display: "inline-block",
                  width: "40px",
                  textAlign: "center",
                  color: "yellow",
                  fontSize: "17px"
                }}
              >
                +
              </span>
              <span
                style={{
                  display: "inline-block",
                  width: "80px",
                  textAlign: "center",
                  color: "red"
                }}
              >
                {selectedImg.bonus}%
              </span>
            </p>
          </div>
          <p
            style={{
              width: "80px",
              textAlign: "center",
              color: "yellow",
              fontSize: "20px"
            }}
          >
            =
          </p>
          <div>
            <p
              style={{
                width: "135px",
                textAlign: "center",
                marginBottom: "-12px"
              }}
            >
              NewAmount
            </p>
            <p
              style={{
                width: "135px",
                textAlign: "center",
                color: "yellowgreen",
                fontSize: "14px",
                backgroundColor: "black",
                borderRadius: "10px"
              }}
            >
              {selectedImg.price +
                (selectedImg.bonus * selectedImg.price) / 100}
            </p>
          </div>
          <div
            style={{
              backgroundImage: `url(${ButtonImg})`,
              backgroundPosition: "center",
              width: "50px",
              height: "20px",
              textAlign: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%"
            }}
          >
            Submit
          </div>
        </div>
      </div>
    </div>
  );
  //personal information part end

  //workinformation part start
  const workinfo = (
    <div className="row">
      <img src={img} width="100%" height="200px" alt="" />
    </div>
  );
  //workinformation part end

  //education part start

  const education = (
    <div className="row">
      <img src={img} width="100%" height="200px" alt="" />
    </div>
  );
  //education part end

  const welcome = (
    <div className="row">
      <img src={img} width="100%" height="200px" alt="" />
    </div>
  );

  const year_bonum = (
    <div className="row">
      <img src={img} width="100%" height="200px" alt="" />
    </div>
  );

  const Monday_Bonus = (
    <div className="row">
      <img src={img} width="100%" height="200px" alt="" />
    </div>
  );

  const Refer_Win = (
    <div className="row">
      <img src={img} width="100%" height="200px" alt="" />
    </div>
  );
  //keyskills part start
  const keyskills = (
    <div className="row">
      <img src={img} width="100%" height="200px" alt="" />
    </div>
  );

  const Refer_Earn = (
    <div className="row">
      <img src={img} width="100%" height="200px" alt="" />
    </div>
  );

  const Weekly_bonus = (
    <div className="row">
      <img src={img} width="100%" height="200px" alt="" />
    </div>
  );

  const genratebody1 = () => {
    switch (obj[activeSectionKey]) {
      case obj.Bonus:
        return personInfo; // if key is "info" then return "personInfo object"
      case obj.Lucky:
        return workinfo; // if key is "WorkEx" then return "personInfo object"
      case obj.Telegram:
        return education; // if key is "edu" then return "personInfo object"
      case obj.Recharge:
        return keyskills; // if key is "KeySkills" then return "personInfo object"
      case obj.Monday_Bonus:
        return Monday_Bonus; // if key is "info" then return "personInfo object"
      case obj.Refer_Win:
        return Refer_Win; // if key is "WorkEx" then return "personInfo object"
      case obj.Refer_Earn:
        return Refer_Earn; // if key is "edu" then return "personInfo object"
      case obj.year_bonum:
        return year_bonum; // if key is "KeySkills" then return "personInfo object"
      case obj.Weekly_bonus:
        return Weekly_bonus; // if key is "edu" then return "personInfo object"
      case obj.welcome:
        return welcome; // if key is "KeySkills" then return "personInfo object"
      default:
        return null;
    }
  };
  return (
    <>
      {loaded ? (
        <Container
          style={{
            position: "relative",
            backgroundColor: "#4c1e14",
            width: "100%",
            top: "50%",
            transform: "translateY(-50%)",
            // marginLeft: "0px",
            //   height: "200px",
            paddingLeft: "3px",
            padding: "5px 0px",
            // marginRight: "0px",
            paddingRight: "3px"
          }}
        >
          <Grid container>
            {/* <Grid
            xs={12}
            style={{
              textAlign: "right"
            }}
          >
            <Close
              sx={{
                color: "white"
              }}
            />
          </Grid> */}
            <Grid item xs={11}>
              <List style={{ display: "flex", padding: "0px 0px" }}>
                {Object.keys(obj)?.map((item) => (
                  <ListItem
                    style={{
                      padding: "0px 0px",
                      display: "flex",
                      marginTop: "1px",
                      textAlign: "center",
                      color: "#e35b04"
                    }} // Set your desired font size, e.g., "16px"
                    key={item}
                    className={`${styless.section} ${
                      activeSectionKey === item ? styless.activee : ""
                    }`}
                    onClick={() => setActiveSectionKey(item)}
                  >
                    <ListItemText className="item" primary={obj[item]} />
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid xs={1} style={{ textAlign: "right" }}>
              <Close
                onClick={onClose}
                sx={{
                  color: "white"
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                fontSize: "11px",
                marginTop: "5px",
                color: "wheat"
                //   height: "200px"
              }}
            >
              {genratebody1()}
            </Grid>
          </Grid>
        </Container>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Adjust the height as needed
            textAlign: "center",
            color: "#bd5c0c"
          }}
        >
          <SyncLoader size={12} color={"#bd5c0c"} />
        </div>
      )}
    </>
  );
};

export default Buy;
