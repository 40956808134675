import {
  // Box,
  Button,
  Container,
  Grid,
  // List,
  // ListItem,
  // ListItemText,
  Typography
} from "@mui/material";
// import leftsideimg from "../../image/icons/background1.jpg";
// import vipbg from "../../image/icons/popup/popup/vip/vip.png";
import vipbg from "../../image/icons/VIP/2.webp";
import sign from "../../image/icons/popup/popup/vip/signbonus.png";
import montly from "../../image/icons/popup/popup/vip/monthly.png";
import weekly from "../../image/icons/popup/popup/vip/weekly.png";
import level from "../../image/icons/popup/popup/vip/levelbonus.png";
import CloseIcon from "@mui/icons-material/Close";
import sideimg from "../../image/icons/popup/popup/vip/bannervip.png";

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SyncLoader } from "react-spinners";

const Vip = ({ onClose }) => {
  const a = [vipbg, sign, montly, weekly, level, sideimg];
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const loadImage = (url) => {
      return new Promise((resolve) => {
        const image = new Image();
        image.src = url;

        image.onload = () => {
          resolve();
        };
        if (image.complete) {
          resolve();
        }
      });
    };

    const loadImages = async () => {
      // Load regular images
      const regularImageLoadPromises = a.map((img) => loadImage(img));

      try {
        await Promise.all(regularImageLoadPromises).then(() => {
          setLoaded(true);
        });
      } catch (error) {
        console.error("Image loading error:", error);
      }
    };

    loadImages();
  }, []);
  return (
    <>
      {loaded ? (
        <Container
          style={{
            backgroundImage: `url(${vipbg})`, // Specify the path to your image
            backgroundSize: "cover", // Adjust as needed
            backgroundRepeat: "no-repeat",
            width: "90%",
            marginTop: "20px",
            paddingLeft: "0px",
            paddingRight: "0px"
          }}
        >
          <Grid container>
            <Grid item xs={9}></Grid>
            <Grid item xs={3} style={{ textAlign: "right" }}>
              <Link onClick={onClose}>
                <CloseIcon style={{ color: "white" }} />
              </Link>
            </Grid>
          </Grid>

          <Grid container>
            <Grid
              item
              xs={6}
              style={{
                paddingLeft: "0px",
                paddingRight: "0px",
                paddingTop: "40px",
                paddingBottom: "10px",
                textAlign: "center"
              }}
            >
              <img src={sideimg} width="68%" alt="" />
              <Typography
                style={{
                  textAlign: "center",
                  color: "white",
                  fontSize: "12px"
                }}
              >
                <Button
                  style={{
                    borderRadius: "10px",
                    color: "white",
                    backgroundColor: "#bb1313"
                  }}
                >
                  Buy
                </Button>
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                paddingLeft: "0px",
                paddingRight: "0px",
                paddingTop: "40px",
                paddingBottom: "10px"
              }}
            >
              <Grid container style={{ marginBottom: "10px" }}>
                <Grid xs={6} style={{ textAlign: "center" }}>
                  <img src={sign} width="40%" alt="" />
                </Grid>
                <Grid xs={6} style={{ textAlign: "center" }}>
                  <img src={weekly} width="40%" alt="" />
                </Grid>
              </Grid>
              <Grid container>
                <Grid xs={6} style={{ textAlign: "center" }}>
                  <img src={montly} width="40%" alt="" />
                </Grid>
                <Grid xs={6} style={{ textAlign: "center" }}>
                  <img src={level} width="40%" alt="" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Adjust the height as needed
            textAlign: "center",
            color: "#bd5c0c"
          }}
        >
          <SyncLoader size={12} color={"#bd5c0c"} />
        </div>
      )}
    </>
  );
};

export default Vip;
