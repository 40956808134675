import { Box, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import header from "../../image/icons/RANK/RANK/10.webp";
import img1 from "../../image/icons/RANK/RANK/8.webp";
import img2 from "../../image/icons/RANK/RANK/7.webp";
import img3 from "../../image/icons/RANK/RANK/6.webp";
import img4 from "../../image/icons/RANK/RANK/5.webp";
import img5 from "../../image/icons/RANK/RANK/4.webp";
import img6 from "../../image/icons/RANK/RANK/3.webp";
import footer from "../../image/icons/RANK/RANK/2.webp";
import { Link } from "react-router-dom";
import { Close } from "@mui/icons-material";
import { SyncLoader } from "react-spinners";

const Rank = ({ onClose }) => {
  const a = [header, footer, img1, img2, img3, img4, img5, img6];
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const loadImage = (url) => {
      return new Promise((resolve) => {
        const image = new Image();
        image.src = url;

        image.onload = () => {
          resolve();
        };
        if (image.complete) {
          resolve();
        }
      });
    };

    const loadImages = async () => {
      // Load regular images
      const regularImageLoadPromises = a.map((img) => loadImage(img));

      try {
        await Promise.all(regularImageLoadPromises).then(() => {
          setLoaded(true);
        });
      } catch (error) {
        console.error("Image loading error:", error);
      }
    };

    loadImages();
  }, []);
  return (
    <>
      {loaded ? (
        <Container
          style={{
            backgroundColor: "#4c1e14",
            width: "98%",

            height: "96%",
            paddingLeft: "0px",
            paddingRight: "0px",
            overflow: "auto",
            paddingTop: "0px",
            textAlign: "center"
          }}
        >
          <Box
            style={{
              margin: "0px 0px ",
              borderRadius: "5px",
              position: "sticky",
              top: "0"
            }}
          >
            <img
              src={header}
              alt=""
              style={{ width: "100%", height: "50px" }}
            />
            <Link>
              <Close
                onClick={onClose}
                sx={{
                  position: "absolute",
                  right: "16px",
                  top: "10px",
                  fontSize: "25px",
                  color: "white"
                }}
              />
            </Link>
          </Box>
          <Box sx={{ overflow: "auto" }}>
            <Box
              style={{
                margin: "0px 10px ",
                borderRadius: "5px"
              }}
            >
              <img src={img1} alt="" style={{ width: "98%" }} />
            </Box>
            <Box
              style={{
                margin: "0px 10px ",
                borderRadius: "5px"
              }}
            >
              <img src={img2} alt="" style={{ width: "98%" }} />
            </Box>
            <Box
              style={{
                margin: "0px 10px ",
                borderRadius: "5px"
              }}
            >
              <img src={img3} alt="" style={{ width: "98%" }} />
            </Box>
            <Box
              style={{
                margin: "0px 10px ",
                borderRadius: "5px"
              }}
            >
              <img src={img4} alt="" style={{ width: "98%" }} />
            </Box>
            <Box
              style={{
                margin: "0px 10px ",
                borderRadius: "5px"
              }}
            >
              <img src={img5} alt="" style={{ width: "98%" }} />
            </Box>
            <Box
              style={{
                margin: "0px 10px ",
                borderRadius: "5px"
              }}
            >
              <img src={img6} alt="" style={{ width: "98%" }} />
            </Box>
            <Box
              style={{
                margin: "0px 10px ",
                borderRadius: "5px"
              }}
            >
              <img src={img2} alt="" style={{ width: "98%" }} />
            </Box>
            <Box
              style={{
                margin: "0px 10px ",
                borderRadius: "5px"
              }}
            >
              <img src={img5} alt="" style={{ width: "98%" }} />
            </Box>
            <Box
              style={{
                margin: "0px 10px ",
                borderRadius: "5px"
              }}
            >
              <img src={img5} alt="" style={{ width: "98%" }} />
            </Box>
            <Box
              style={{
                margin: "0px 10px ",
                borderRadius: "5px"
              }}
            >
              <img src={img5} alt="" style={{ width: "98%" }} />
            </Box>
            <Box
              style={{
                margin: "0px 10px ",
                borderRadius: "5px"
              }}
            >
              <img src={img5} alt="" style={{ width: "98%" }} />
            </Box>
            <Box
              style={{
                margin: "0px 10px ",
                borderRadius: "5px"
              }}
            >
              <img src={img5} alt="" style={{ width: "98%" }} />
            </Box>
            <Box
              style={{
                margin: "0px 10px ",
                borderRadius: "5px"
              }}
            >
              <img src={img5} alt="" style={{ width: "98%" }} />
            </Box>
            <Box
              style={{
                margin: "0px 10px ",
                borderRadius: "5px"
              }}
            >
              <img src={img5} alt="" style={{ width: "98%" }} />
            </Box>
            <Box
              style={{
                margin: "0px 10px ",
                borderRadius: "5px"
              }}
            >
              <img src={img5} alt="" style={{ width: "98%" }} />
            </Box>
          </Box>
          <Box
            style={{
              margin: "0px 00px ",
              borderRadius: "5px",
              position: "sticky",
              bottom: "0px"
            }}
          >
            <img
              src={footer}
              alt=""
              style={{ width: "100%", height: "52px" }}
            />
          </Box>
        </Container>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Adjust the height as needed
            textAlign: "center",
            color: "#bd5c0c"
          }}
        >
          <SyncLoader size={12} color={"#bd5c0c"} />
        </div>
      )}
    </>
  );
};

export default Rank;
