import { Button, Container, Dialog, DialogContent } from "@mui/material";
// import bg from "../image/icons/graphics (1)/a1.png";
import bg from "../image/icons/LOGIN/LOGIN/2.webp";
import bgimg from "../image/icons/LOGIN/LOGIN/1.webp";
import React, { useState } from "react";
import UserLogin from "./UserLogin";

const Loginpage = () => {
  const [openModal, setOpenModal] = useState(false);

  const handleLoginClick = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const renderDialogContent = () => {
    // Implement your logic to render different content based on the selectedButtonType
    return <UserLogin onClose={handleCloseModal} />;
  };

  return (
    <>
      <Container
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundAttachment: "fixed",
          width: "100%",
          height: "100%",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Button
          style={{
            color: "white",
            padding: "2px 5px"
          }}
          onClick={handleLoginClick} // Corrected function name
        >
          <img src={bgimg} width="30%" alt="" />
        </Button>
        <Dialog
          open={openModal}
          onClose={handleCloseModal}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              width: "100%",
              boxShadow: "none",
              height: "100%",
              paddingBottom: "0"
            }
          }}
        >
          <DialogContent>{renderDialogContent()}</DialogContent>
        </Dialog>
      </Container>
    </>
  );
};

export default Loginpage;
