import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// import img from "../src/image/icons/popup-bg.jpg";
import "./App.css";
import Popup from "./component/Popup/Popup";
import Rank from "./component/Rank_Popup/Rank";
import Bonus from "./component/Bonus/Bonus";
import Mail from "./component/Mail/Mail";
import Vip from "./component/Vip/Vip";
// import bg from "../src/image/icons/BG1.png";
import Buy from "./component/Shop_Buy/Buy";
import Setting from "./component/Setting/Setting";
import UserLogin from "./component/UserLogin";
import OtpPage from "./component/OtpPage";
import User from "./component/User_Profile/User";
import Withdraw from "./component/withdraw/Withdraw";
import Bank from "./component/withdraw/Bank";
import support from "./image/icons/OneDrive_2024-01-10/Lobby Webp/q5.jpg";
import Upi from "./component/withdraw/Upi";
import Refer from "./component/Refer_Win/Refer";
import ReferWin from "./component/Refer_Earn/ReferWin";
import PrivateRoute from "./component/PrivateRoute";
import Innerpopup from "./component/Bonus/Innerpopup";
import LoginPage from "./component/Loginpage";
import Gamelobby from "./component/Gamelobby";
import EditName from "./component/User_Profile/EditName";
import { SyncLoader } from "react-spinners";
// import MusicControl from "./component/MusicControl.jsx";
const Home = lazy(() => import("./component/Home.jsx"));

function App() {
  return (
    <div className="app-container">
      <div
        className="background-image"
        style={{
          backgroundImage: `url(${support})`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundAttachment: "fixed",
          width: "100%"
        }}
      >
        <div className="overlay"></div>
        <div className="component-container">
          <Router>
            <Routes>
              <Route path="/" element={<PrivateRoute />}>
                <Route
                  index
                  element={
                    <Suspense
                      fallback={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100vh", // Adjust the height as needed
                            textAlign: "center",
                            color: "#bd5c0c"
                          }}
                        >
                          <SyncLoader size={12} color={"#bd5c0c"} />
                        </div>
                      }
                    >
                      <Home />
                    </Suspense>
                  }
                />
                <Route path="popup" element={<Popup />} />
                <Route path="Rank" element={<Rank />} />
                <Route path="Bonus" element={<Bonus />} />
                <Route path="Mail" element={<Mail />} />
                <Route path="userprofile" element={<User />} />
                <Route path="Vip" element={<Vip />} />
                <Route path="Upi" element={<Upi />} />
                <Route path="Buy" element={<Buy />} />
                <Route path="referwin" element={<Refer />} />
                <Route path="referearn" element={<ReferWin />} />
                <Route path="Setting" element={<Setting />} />
                <Route path="editname" element={<EditName />} />
                <Route path="bonusinnser" element={<Innerpopup />} />
                {/* <Route path="music" element={<MusicControl />} /> */}
                <Route path="withdraw" element={<Withdraw />} />
                <Route path="Game" element={<Gamelobby />} />
                <Route path="bank" element={<Bank />} />
              </Route>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/userlogin" element={<UserLogin />} />
              <Route path="/otppage" element={<OtpPage />} />
            </Routes>
          </Router>
        </div>
      </div>
    </div>
  );
}

export default App;
