import React, { useEffect, useState } from "react";
// import settingbg from "../../image/icons/graphics (1)/settingimg.png";
import {
  Container,
  Dialog,
  DialogContent,
  Grid
  // Typography
} from "@mui/material";
// import frame from "../../image/icons/graphics (1)/19.png";

import frame from "../../image/icons/profile/3.webp";
import coin from "../../image/icons/User_Profile/1.png";
import que from "../../image/icons/profile/11.webp";
// import editIcon from "../../image/icons/profile/12.webp";
import plate2 from "../../image/icons/User_Profile/6.png";
import leftsde from "../../image/icons/profile/9.webp";
import right from "../../image/icons/profile/10.webp";
// import editIcon from "../../image/icons/User_Profile/3.png";
// import que from "../../image/icons/User_Profile/4.png";
import userHeaderimg from "../../image/icons/profile/15.webp";
// import patti from "../../image/icons/User_Profile/7.png";
import { Close } from "@mui/icons-material";
import { Link } from "react-router-dom";
import Buy from "../Shop_Buy/Buy";
import { SyncLoader } from "react-spinners";
const User = ({ onClose }) => {
  const [loaded, setLoaded] = useState(false);
  const a = [frame, coin, que, plate2, leftsde, right, userHeaderimg];
  useEffect(() => {
    const loadImage = (url) => {
      return new Promise((resolve) => {
        const image = new Image();
        image.src = url;

        image.onload = () => {
          resolve();
        };
        if (image.complete) {
          resolve();
        }
      });
    };

    const loadImages = async () => {
      // Load regular images
      const regularImageLoadPromises = a.map((img) => loadImage(img));

      try {
        await Promise.all(regularImageLoadPromises).then(() => {
          setLoaded(true);
        });
      } catch (error) {
        console.error("Image loading error:", error);
      }
    };

    loadImages();
  }, []);

  const [openModal, setOpenModal] = useState(false);
  const [selectedButtonType, setSelectedButtonType] = useState(null);
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedButtonType(null); // Reset selectedButtonType when closing the modal
  };

  const handleButtonClick = (buttonType) => {
    setOpenModal(true);
    setSelectedButtonType(buttonType);
  };
  const DialogContental = () => {
    switch (selectedButtonType) {
      case "buy":
        return <Buy onClose={handleCloseModal} />;
      default:
        return null;
    }
  };
  return (
    <>
      {loaded ? (
        <Container
          style={{
            width: "100%",
            height: "100%",
            paddingLeft: "0px",
            paddingRight: "0px"
          }}
        >
          <Grid container style={{ position: "relative" }}>
            <Grid
              xs={3}
              style={{
                backgroundImage: `url(${leftsde})`, // Specify the path to your image
                backgroundSize: "100% 95%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "95%",
                height: "260px",
                paddingLeft: "0px",
                paddingRight: "0px",
                overflow: "auto",
                paddingTop: "0px",
                position: "relative",
                textAlign: "center"
              }}
              // style={{
              //   backgroundColor: "#3f0202eb",
              //   height: "260px",
              //   borderBottomLeftRadius: "10px",
              //   borderTopLeftRadius: "10px"
              // }}
            >
              <img
                src={frame}
                alt=""
                style={{ width: "60%", display: "block", margin: "50px auto" }}
              />
            </Grid>
            <Grid
              xs={9}
              style={{
                backgroundImage: `url(${right})`, // Specify the path to your image
                backgroundSize: "100% 95%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "95%",
                height: "260px",
                paddingLeft: "0px",
                paddingRight: "0px",
                overflow: "auto",
                paddingTop: "0px",
                position: "relative",
                textAlign: "center"
              }}
              // style={{
              //   backgroundColor: "#5d0101eb",
              //   height: "260px",
              //   borderTopRightRadius: "10px",
              //   borderBottomRightRadius: "10px",
              //   position: "relative",
              //   paddingTop: "50px"
              // }}
            >
              <div
                style={{
                  margin: "0px auto",
                  textAlign: "center",
                  padding: "0px",
                  width: "90%",
                  borderRadius: "10px",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  top: "50%",
                  transform: "translateY(-50%)"
                }}
              >
                <img src={plate2} alt="" style={{ width: "100%" }} />
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    textAlign: "center",
                    margin: "auto"
                  }}
                >
                  <div style={{ margin: "auto 10px" }}>
                    <span
                      style={{
                        display: "inline-block",
                        width: "50px",
                        textAlign: "center"
                      }}
                    ></span>
                    <span
                      style={{
                        display: "inline-block",
                        width: "80px",
                        textAlign: "center",
                        color: "yellow"
                      }}
                    >
                      Name
                    </span>
                    <p
                      style={{
                        width: "100px",
                        backgroundColor: "#130101",
                        color: "brown",
                        fontSize: "14px",
                        borderRadius: "10px",
                        textAlign: "center",
                        display: "inline-block",
                        margin: "0",
                        padding: "0"
                      }}
                    >
                      Guest143829
                    </p>
                    <span
                      style={{
                        display: "inline-block",
                        width: "50px",
                        textAlign: "center"
                      }}
                    >
                      <Link to={"editname"}>
                        <img src={que} style={{ width: "20px" }} alt="" />
                      </Link>
                    </span>
                  </div>

                  <div style={{ margin: "10px" }}>
                    <span
                      style={{
                        display: "inline-block",
                        width: "50px",
                        textAlign: "center"
                      }}
                    >
                      <img src={coin} style={{ width: "20px" }} alt="" />
                    </span>
                    <span
                      style={{
                        display: "inline-block",
                        width: "80px",
                        textAlign: "center",
                        color: "yellow"
                      }}
                    >
                      Capital
                    </span>
                    <p
                      style={{
                        width: "100px",
                        backgroundColor: "#130101",
                        color: "brown",
                        fontSize: "14px",
                        borderRadius: "10px",
                        textAlign: "center",
                        display: "inline-block",
                        margin: "0",
                        padding: "0"
                      }}
                    >
                      Guest143829
                    </p>
                    <span
                      style={{
                        display: "inline-block",
                        width: "50px",
                        textAlign: "center"
                      }}
                    ></span>
                  </div>

                  <div style={{ margin: "10px" }}>
                    <span
                      style={{
                        display: "inline-block",
                        width: "50px",
                        textAlign: "center"
                      }}
                    >
                      <img src={coin} style={{ width: "20px" }} alt="" />
                    </span>
                    <span
                      style={{
                        display: "inline-block",
                        width: "80px",
                        textAlign: "center",
                        color: "yellow"
                      }}
                    >
                      Bonus
                    </span>
                    <p
                      style={{
                        width: "100px",
                        backgroundColor: "#130101",
                        color: "brown",
                        fontSize: "14px",
                        borderRadius: "10px",
                        textAlign: "center",
                        display: "inline-block",
                        margin: "0",
                        padding: "0"
                      }}
                    >
                      Guest143829
                    </p>
                    <span
                      style={{
                        display: "inline-block",
                        width: "50px",
                        textAlign: "center"
                      }}
                    >
                      <img
                        src={que}
                        style={{ width: "20px" }}
                        alt=""
                        onClick={() => {
                          handleButtonClick("buy");
                        }}
                      />
                    </span>
                  </div>
                  <div
                    className=""
                    style={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <p
                      className=""
                      style={{
                        paddingBlock: "0px",
                        paddingTop: "0px",
                        marginBottom: "0px",
                        color: "white",
                        marginTop: "0px",
                        width: "25%",
                        fontSize: "18px",
                        fontWeight: "bold",
                        padding: "0px 6px",
                        borderRadius: "6px",
                        backgroundColor: "#049795"
                      }}
                    >
                      Logout
                    </p>
                  </div>
                </div>
              </div>

              {/* <p style={{ textAlign: 'center', color: 'yellow', margin: '0px', padding: '0px' }}>Binding</p> */}
            </Grid>
            <Grid xs={12} style={{ position: "absolute", width: "100%" }}>
              <div style={{ position: "relative" }}>
                <img src={userHeaderimg} style={{ width: "100%" }} alt="" />
                <Link>
                  <Close
                    onClick={onClose}
                    sx={{
                      position: "absolute",
                      right: "7px",
                      color: "white",
                      top: "5px"
                    }}
                  />
                </Link>
              </div>
            </Grid>
          </Grid>
          <Dialog
            open={openModal}
            onClose={handleCloseModal}
            PaperProps={{
              style: {
                backgroundColor: "transparent",
                width: "100%",
                boxShadow: "none",
                height: "100%",
                paddingBottom: "0"
              }
            }}
          >
            <DialogContent>{DialogContental()}</DialogContent>
          </Dialog>
        </Container>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Adjust the height as needed
            textAlign: "center",
            color: "#bd5c0c"
          }}
        >
          <SyncLoader size={12} color={"#bd5c0c"} />
        </div>
      )}
    </>
  );
};

export default User;
