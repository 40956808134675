import { Container, Grid } from "@mui/material";
import React, { useState } from "react";
import popup from "../../image/icons/popup-bg.jpg";
import bank from "../../image/icons/2.png";
// import innerimg from "../../image/icons/graphics (1)/5.png";
import axios from "axios";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const Upi = () => {
  const [formdata, setFormdata] = useState({
    holdername: "",
    upiaddress: "",
    data: new Date().toISOString()
  });
  const jwtyoken = Cookies.get("token");
  const bycrypt = jwtDecode(jwtyoken);
  const navigate = useNavigate();
  const Userid = bycrypt._id;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormdata((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (!formdata.holdername || !formdata.upiaddress) {
      toast.error("All fields are required", {
        position: toast.POSITION.TOP_CENTER
      });
      return;
    }
    try {
      const response = await axios.post(
        "https://ruby-important-panther.cyclic.app/UpiDeatilsOfUser",
        { upidetailsdata: formdata, userid: Userid },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      if (response.status >= 200 && response.status < 300) {
        console.log("Form data submitted successfully");
        toast.success("Successfully submitted details", {
          position: toast.POSITION.TOP_CENTER
        });
        // navigate("/");
      } else {
        console.error("Failed to submit form data");
        toast.error("Failed to submit form data", {
          position: toast.POSITION.TOP_CENTER
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error("An error occurred", {
        position: toast.POSITION.TOP_CENTER
      });
    }
  };

  return (
    <>
      <Container
        style={{
          backgroundImage: `url(${popup})`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundAttachment: "fixed", // Add this line
          width: "100%",
          height: "100%"
        }}
      >
        <Grid
          container
          style={{
            backgroundImage: `url(${bank})`,
            backgroundSize: "64% 100%",
            paddingTop: "22px",
            backgroundRepeat: "no-repeat",
            paddingBottom: "30px",
            backgroundPosition: "center center",
            backgroundAttachment: "fixed", // Add this line
            width: "70%",
            height: "70%"
          }}
        >
          <Grid
            xs={12}
            style={{
              backgroundRepeat: "no-repeat",
              paddingTop: "30px",
              backgroundPosition: "center"
            }}
          >
            <form onSubmit={handleFormSubmit}>
              <p
                style={{
                  marginBottom: "0px",
                  marginTop: "15px",
                  color: "#cd8264"
                }}
              >
                <span style={{ fontSize: "14px", paddingRight: "5px" }}>
                  UPI address
                </span>
                <input
                  placeholder="Upi Address"
                  type="text"
                  name="upiaddress"
                  value={formdata.upiaddress}
                  onChange={handleChange}
                  style={{
                    borderRadius: "10px",
                    color: "white",
                    textAlign: "center",
                    padding: "2px 5px",
                    backgroundColor: "#310e0e"
                  }}
                />
              </p>
              <p
                style={{
                  marginBottom: "0px",
                  marginTop: "15px",
                  color: "#cd8264"
                }}
              >
                <span style={{ fontSize: "14px", paddingRight: "5px" }}>
                  Holder Name
                </span>
                <input
                  placeholder="Upi Address"
                  type="text"
                  name="holdername"
                  value={formdata.holdername}
                  onChange={handleChange}
                  style={{
                    borderRadius: "10px",
                    color: "white",
                    textAlign: "center",
                    padding: "2px 5px",
                    backgroundColor: "#310e0e"
                  }}
                />
              </p>
              <button
                type="submit"
                style={{
                  marginTop: "20px",
                  marginBottom: "0px",
                  fontSize: "14px",
                  textAlign: "center",
                  borderRadius: "10px",
                  width: "20%",
                  fontWeight: "bold",
                  padding: "5px 15px",
                  color: "#cd8264",
                  backgroundColor: "#94ffea"
                }}
              >
                Submit
              </button>
            </form>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Upi;
