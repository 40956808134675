import React, { useState } from "react";
// import img2 from "../image/icons/graphics (1)/19.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import loginIn from "../image/icons/graphics (1)/logn.png";
// import { jwtDecode } from "jwt-decode";
import { Container, Grid } from "@mui/material";
// import login from "../image/icons/RANK/RANK/13.webp";

const UserLogin = () => {
  var navigate = useNavigate();

  const [formdata, setFormdata] = useState({
    userphone: "",
    userotp: 0,
    usereffral: "",
    username: "rocky",
    verification: "0",
    reffralcode: ""
  });

  const handleChangeform = (e) => {
    const { name, value } = e.target;
    setFormdata((formdata) => ({
      ...formdata,
      [name]: value
    }));
  };

  const SendOtp = async (otp) => {
    // console.log("Before sending OTP:", otp);/
    console.log("Before sending OTP:", formdata.userphone);
    try {
      const response = await axios.post(
        `https://ruby-important-panther.cyclic.app/send-otp`,
        {
          userphone: formdata.userphone,
          userotp: otp
        }
      );

      if (response.data) {
        // console.log("Data Sent Successfully", response.data);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const submitdata = async (e) => {
    e.preventDefault();
    // Validate the entered number length
    if (formdata.userphone.length < 10) {
      toast.error("Please enter a valid number", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      return; // Prevent API call if validation fails
    }

    try {
      const otp = Math.floor(1000 + Math.random() * 9000);
      const updatedFormData = {
        ...formdata,
        userotp: otp
      };
      setFormdata(updatedFormData);
      // console.log(updatedFormData);
      const response = await axios.post(
        "https://ruby-important-panther.cyclic.app/UserloginData",
        updatedFormData,
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );

      // Handle the response based on your application logic
      // console.log(response.data.message, "response.data.message");
      if (
        response.data &&
        response.data.message === "already exist this number"
      ) {
        navigate("/");
        // console.log("Response object:", response.data.token);
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 30);
        Cookies.set("token", response.data.token, {
          expires: expirationDate,
          path: "/"
        });
      } else {
        if (response && response.data) {
          navigate("/otppage");
          await SendOtp(updatedFormData.userotp);
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      if (error.response) {
        toast.error("Entered information is invalid", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        console.error("Response data:", error.response.data.message);
      }
    }
  };

  return (
    <>
      <Container>
        <Grid
          container
          style={{
            backgroundImage: `url(${loginIn})`,
            backgroundSize: "85% 100%",
            backgroundRepeat: "no-repeat",
            paddingTop: "10px",
            backgroundPosition: "center center"
          }}
        >
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <div className="main_form_start">
              <form
                action=""
                onSubmit={submitdata}
                style={{ marginTop: "0px" }}
              >
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    marginTop: "25px",
                    color: "#ffc7bf",
                    marginBottom: "0px",
                    fontWeight: "bold"
                  }}
                >
                  Login.
                </p>
                <p
                  style={{
                    textAlign: "left",
                    paddingLeft: "12px",
                    color: "#918f8d",
                    marginTop: "20px",
                    marginBottom: "0px"
                  }}
                >
                  <label
                    htmlFor=""
                    style={{ fontSize: "15px", color: "#f1e2e0" }}
                  >
                    Enter Mobile Number
                  </label>
                </p>
                <p
                  style={{
                    marginTop: "0px",
                    marginBottom: "0px",
                    paddingTop: "0px",
                    paddingBottum: "0px",
                    color: "#ffc7bf"
                  }}
                >
                  <input
                    style={{
                      width: "94%",
                      color: "#918f8d",
                      margin: "0% 2%",
                      padding: "5px 5px",
                      borderRadius: "5px",
                      backgroundColor: "#331115"
                    }}
                    type="number"
                    name="userphone"
                    onChange={handleChangeform}
                    placeholder="Enter Number"
                    value={formdata.userphone}
                    id=""
                  />
                </p>
                <p
                  style={{
                    textAlign: "left",
                    paddingLeft: "12px",
                    color: "#ffc7bf",
                    marginBottom: "0px"
                  }}
                >
                  <label
                    htmlFor=""
                    style={{ fontSize: "15px", color: "#ffc7bf" }}
                  >
                    Enter Friend's Referral Code
                  </label>
                </p>
                <p
                  style={{
                    marginTop: "0px",
                    marginBottom: "0px",
                    paddingTop: "0px",
                    paddingBottum: "0px"
                  }}
                >
                  <input
                    style={{
                      width: "94%",
                      backgroundColor: "#331115",
                      margin: "0% 2%",
                      color: "#ffc7bf",
                      padding: "5px 5px",
                      borderRadius: "5px"
                    }}
                    type="text"
                    name="usereffral"
                    onChange={handleChangeform}
                    placeholder="Referral Code"
                    value={formdata.usereffral}
                    id=""
                  />
                </p>
                <p>
                  <button
                    style={{
                      backgroundColor: "green",
                      color: "azure",
                      width: "98%",
                      justifyContent: "center",
                      margin: "0% 1%",
                      borderRadius: "6px",
                      padding: "7px 5px"
                    }}
                  >
                    Get Started
                  </button>
                </p>
              </form>
            </div>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
        <ToastContainer />
      </Container>
      {/* <div
        className="login_main_div"
        style={{ textAlign: "center", paddingTop: "70px" }}
      >
        <div
          className="login_game_logo"
          style={{ textAlign: "center", paddingBottom: "30px" }}
        >
          <img src={img2} width="100px" alt="" />
        </div> */}

      {/* <div className="login_text">
          <h4
            style={{
              fontSize: "23px",
              fontWeight: "500",
              color: "antiquewhite",
              marginBottom: "0px"
            }}
          >
            Login/Signup
          </h4>
          <p style={{ color: "#918f8d", fontSize: "19px" }}>Signup and get</p>
        </div> */}
      {/* <div className="main_form_start">
          <form action="" onSubmit={submitdata}>
            <p
              style={{
                textAlign: "left",
                paddingLeft: "12px",
                color: "#918f8d",
                marginBottom: "0px"
              }}
            >
              <label htmlFor="">Enter Mobile Number</label>
            </p>
            <p>
              <input
                style={{ width: "94%", margin: "0% 2%", padding: "5px 5px" }}
                type="number"
                name="userphone"
                onChange={handleChangeform}
                placeholder="Enter Number"
                value={formdata.userphone}
                id=""
              />
            </p>
            <p
              style={{
                textAlign: "left",
                paddingLeft: "12px",
                color: "#918f8d",
                marginBottom: "0px"
              }}
            >
              <label htmlFor="">Enter Friend's Referral Code</label>
            </p>
            <p>
              <input
                style={{ width: "94%", margin: "0% 2%", padding: "5px 5px" }}
                type="text"
                name="usereffral"
                onChange={handleChangeform}
                placeholder="Referral Code"
                value={formdata.usereffral}
                id=""
              />
            </p>
            <p>
              <button
                style={{
                  backgroundColor: "green",
                  color: "azure",
                  width: "96%",
                  margin: "0% 1%",
                  borderRadius: "6px",
                  padding: "7px 5px"
                }}
              >
                Get Started
              </button>
            </p>
          </form>
        </div> */}
      {/* </div> */}
      <ToastContainer />
    </>
  );
};

export default UserLogin;
